.print-logo {
	display: none;
}

.mb-docs {
	padding-bottom: 6rem;

	@include media($tablet) {
		padding-bottom: 4rem;
	}
} // .mb-docs

.mb-resource-header {
	position: relative;
}

.mb-resource-buttons {
	@include media($mobile-landscape) {
		position: absolute;
		top: .25rem;
		right: 0;
	}

	@include media($tablet-wide) {
		top: .5rem;
	}
} // .mb-resource-buttons

.mb-table {
	width: 100%;
	margin-top: -2rem;
	min-width: 600px;
	margin: 0;

	&-wrapper {
		overflow-x: auto;
	}

	td {
		padding: 10px 1rem;
		border-bottom: 1px solid $border-color;
	}

	&-header td {
		text-align: center;
		font-weight: 600;

		.fonts-loaded & {
			font-weight: 400;
			font-family: $proxima-600;
		}
	}

	&__thaw {
		text-align: center;

		td {
			width: 24%;

			@include media($tablet) {
				width: 20%;
			}
		}

		td:nth-child(1) {
			width: 28%;
			text-align: left;

			@include media($tablet) {
				width: 40%;
			}
		}

		td:nth-child(3) {
			background-color: $grey-1;
		}
	} // .mb-table__thaw

	&__prep {
		td {
			vertical-align: top;
		}

		td:nth-child(1) {
			width: 20%;

			@include media($tablet) {
				width: 24%;
			}
		}

		td:nth-child(2) {
			text-align: center;
			background-color: $grey-1;
			width: 20%;

			@include media($tablet) {
				width: 18%;
			}
		}

		td:nth-child(3) {
			text-align: left;
			width: 60%;

			@include media($tablet) {
				width: 58%;
			}

			&::first-letter {
				text-transform: capitalize;
			}

			.detailed-resource-list {
				margin-bottom: 0;
			}
		}
	} // .mb-table__prep
} // .mb-table
