$social-height: 24px;

.oamm-social {
	position: absolute !important;
	bottom: $default-font-size + ((32px - $social-height) / 2);
	left: 1rem;

	.btn {
		float: left;
		border-radius: 3px;
		height: $social-height;
		line-height: $social-height;
		width: $social-height;
		margin-right: 7px;
		padding: 0;
		overflow: hidden;
		text-align: center;
		text-decoration: none;
		text-shadow: none;

		@include media($tablet-wide) {
			i.fa {
				display: block;
				margin: 0;
			}
		}

		&-pin {
			font-size: $social-height;
		}

		&-facebook {
			background-color: #FFF !important;
			font-size: $social-height;
			border: 0;

			i.fa {
				color: #4267B2;
				font-size: $social-height + 6;
				margin-top: -3px;
				margin-left: -1px;
			}
		}
	} // .btn
} // .oamm-social
