.mb-cooking-day-instruction {
	@extend .clearfix;
	margin-bottom: 2rem;

	&-step {
		float: left;
		font-family: $serif;
		font-size: 2rem;
		font-style: italic;
		display: block;
		width: 40px;
		text-align: right;
	}

	&-direction {
		float: right;
		width: 100%;
		padding-top: 8px;
		padding-left: 55px;
		margin-left: -55px;
	}

	&-action {
		@extend .small;
		margin-bottom: 10px;
		color: $teal;
	}

	&-recipe {
		display: block;
		font-size: 1.2rem;
		font-weight: 700;
		margin-bottom: 10px;

		.fonts-loaded & {
			font-weight: 400;
			font-family: $proxima-700;
		}
	}
} // .mb-cooking-day-instruction
