.mb-tabs {
	@extend .clearfix;
	margin: 0 -1rem;

	@include media($tablet) {
		float: left;
		width: percentage(270 / 900);
		margin: 1rem 0 0;
	}

	&-nav {
		@extend .list-reset;
		text-align: center;
		border-bottom: 1px solid $border-color;

		@include media($tablet) {
			display: none;
		}

		li {
			display: inline;
		}

		a {
			color: $text;
			font-weight: 600;
			display: inline-block;
			padding-bottom: 10px;
			margin: 0 10px -1px;
			text-decoration: none;
			text-transform: uppercase;

			&:hover {
				color: $teal;
			}

			.fonts-loaded & {
				font-family: $proxima-600;
				font-weight: 400;
			}

			&.current {
				color: $orange;
				border-bottom: 1px solid $orange;
			}
		}
	} // .mb-tabs-nav

	&-content {
		display: none;
		padding: 1rem 1rem 2rem;

		@include media($tablet) {
			display: block;
			padding: 0;
			margin-bottom: 2rem;
		}

		&.current {
			display: block;
		}

		&-title {
			display: none;

			@include media($tablet) {
				margin: 0 0 20px;
				display: block;
			}
		}

		ul {
			@include spacing(0);
			list-style: none;

			li {
				margin-bottom: 8px;
			}
		}
	} // .mb-tabs-content
} // .mb-tabs
