.mb-stats-bar {
	position: relative;

	@include media($tablet) {
		margin-bottom: 20px;
	}

	&-title {
		float: left;
		width: 100%;
		margin: 1rem -2rem 1rem 0;
		padding-right: 2rem;
		font-size: 1.4rem;

		@include media($tablet) {
			display: none !important;
		}

		.fonts-loaded & {
			font-family: $proxima-600;
			font-weight: 400;
		}

		&-edit-button {
			font-size: 1rem;
			float: right;
			margin-right: -2rem;
			font-family: $proxima-400;

			i {
				float: left;
				margin-top: 3px;
				margin-right: 4px;
			}
		}
	} // .mb-stats-bar-title

	&-edit {
		display: none;
		position: relative;
		margin: 1rem 0;
		padding: 1rem;
		background-color: #FFF;
		box-shadow: 0 0 0 1px rgba($teal, .75), 0 3px 1px -2px rgba($teal, .5), 0 0 8px 0 rgba($teal, .75);
		border-radius: 3px;

		@include media($tablet) {
			display: none !important;
		}

		&.loading {
			background-color: transparent;

			textarea {
				background: url('../images/loading.svg') no-repeat 50% 50%;
			}
		}

		&-textarea {
			width: 100%;
			border: 1px solid transparent;
			box-shadow: none;
			padding: 0;
			overflow: auto;
			resize: none;
			line-height: 1.6;
		}

		&-buttons {
			@include media($tablet) {
				position: absolute;
				right: 1rem;
				bottom: 1rem;
			}

			.btn {
				border: 0;
				box-shadow: none;
				font-size: 1rem;
			}
		}
	} // .mb-stats-bar-edit

	&-favorite {
		float: right;
		font-size: 2rem;
		width: 2rem;
		height: 40px;
		line-height: 40px;
		margin-left: 20px;
		color: $pink;

		@include media($tablet) {
			margin-top: 20px;
		}

		&:hover {
			animation: pulse 1s infinite;
		}
	} // .mb-stats-bar-favorite

	&-cook {
		@include media($tablet) {
			float: left;
			margin: 20px 0;
			height: 40px;
			max-width: 285px;
		}

		&-image {
			@include size(40px);
			float: left;
			border-radius: 50%;
			margin-right: 7px;
		}

		&-details {
			float: left;

			@include media($tablet) {
				max-width: 285px - 47;
			}

			small {
				color: $black-0;
			}

			&-name {
				font-family: $serif;
				font-size: .8rem;
				font-style: italic;
				line-height: 1.6;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;

				@include media($tablet) {
					font-size: 1rem;
				}

				a {
					text-decoration: underline;
				}

				i.fa {
					color: $teal;
					margin-top: 5px;
					margin-left: 5px;
					float: right;

					@include media($tablet) {
						margin-top: 6px;
					}
				}
			} // .mb-stats-bar-cook-details-name
		} // .mb-stats-bar-cook-details
	} // .mb-stats-bar-cook

	.oamm-social {
		$btn-size: 32px;

		display: none;
		float: right;
		position: relative !important;
		bottom: auto;
		left: auto;
		margin-top: $default-font-size + ((50px - $btn-size) / 2);

		@include media($tablet) {
			display: block;
		}

		.btn {
			line-height: $btn-size;
			height: $btn-size;
			width: $btn-size;
			margin-right: 10px;

			&:last-of-type {
				margin-right: 0;
			}

			i.fa {
				line-height: $btn-size;
				font-size: 1.2rem;
			}

			&-pin i.fa {
				font-size: 30px;
			}

			&-facebook i.fa {
				font-size: 41px;
				margin-top: 1px;
				margin-left: -2px;
			}
		}
	}
} // .mb-stats-bar
