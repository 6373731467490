.sidebar {
	@include media($tablet) {
		float: left;
		padding-right: 1em;
		width: 28%;

		&.border {
			padding-right: 1.5em;
		}

		.main-col-left + &.sidebar-right {
			float: right;
			padding-left: 1.5em;
			padding-right: 0;
		}
	}

	@include media($desktop) {
		width: 20%;

		&.border {
			padding-right: 2em;
		}

		&-right {
			float: right;
			padding-left: 1em;
			padding-right: 0;

			&.border {
				padding-left: 2em;
				padding-bottom: 2rem;
				padding-right: 0;
				margin-bottom: 0;
			}
		}

		.main-col-left + &.sidebar-right {
			padding-left: 2em;
			padding-right: 0;
		}
	}

	&-right {
		margin-bottom: 3em;
	}

	&-media {
		width: 300px;
		padding-right: 0 !important;
		padding-left: 0 !important;
	}

	&-hide {
		display: none;
	}

	h2 {
		margin-bottom: 0;
	}

	p {
		margin-top: 5px;
		margin-bottom: 0;

		&.sidebar-info {
			margin-top: 1rem;
			margin-bottom: 1rem;
		}
	}
} // .sidebar

@media screen and (min-width: 960px) and (max-width: 1210px) {
	.nc_socialPanelSide {
		display: none !important;
	}
}
