.conversations {
	@include media($tablet) {
		clear: both;
	}

	&-search {
		display: none;

		@include media($tablet) {
			display: block;
			margin-bottom: 1.5em;
		}

		&-input {
			height: 42px;
		}

		&-submit {
			border-radius: 0 2px 2px 0;
			height: 42px;
		}
	} // .search

	&-table {
		width: 100%;

		thead {
			display: none;

			@include media($tablet) {
				background-color: $grey-1;
				display: table-header-group;
			}

			td {
				padding-top: 3.5em !important;
			}
		}

		td {
			display: block;
			padding: 1em;

			@include media($tablet) {
				display: table-cell;
			}
		}

		a {
			color: $text;

			&:hover {
				color: $orange;
			}
		}

		tbody {
			@include media($tablet-down) {
				tr:nth-child(1n+6) {
					display: none;
				}
			}

			td {
				vertical-align: top;

				@include media($tablet) {
					border-bottom: 1px solid $grey-1;
				}
			}

			.main-link {
				padding-bottom: 0;
				line-height: 1.35;

				@include media($tablet) {
					padding-bottom: 1.25em;
				}
			} // .main-link

			.num.posts {
				@include media($tablet-down) {
					border-bottom: 1px solid $grey-1;
					font-family: $serif;
					font-size: 13px;
					font-style: italic;


					&:after {
						content: ' Replies';
					}
				}

				@include media($tablet) {
					text-align: center;

					&:after {
						display: none;
					}
				}
			} // .num.posts
		} // tbody

		.category {
			display: none;

			@include media($tablet) {
				display: table-cell;
			}
		} // .category
	} // .conversations-table
} // .conversations
