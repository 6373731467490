.mb-new-menu {
	display: none;
	position: fixed;
	z-index: 999999;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 2rem;
	background-color: rgba(#FFF, .97);

	&-close {
		position: absolute;
		top: 1rem;
		right: 1rem;
		font-size: 1.6rem;
		color: $text !important;

		&:hover {
			color: $teal !important;
		}
	} // .mb-new-menu-close

	&-container {
		position: absolute;
		top: 50%;
		right: 2rem;
		left: 2rem;
		transform: translateY(-75%);

		@include media($tablet) {
			top: 33.333%;
			transform: translateY(-75%) translateX(-50%);
			max-width: 400px;
			left: 50%;
		}
	}

	&-title {
		text-align: center;
	}

	$new-menu-form-size: 40px;

	&-form {
		@extend .clearfix;
		border: 1px solid $border-color;
		border-radius: 3px;
		padding: 3px;
		text-align: center;

		&.focus {
			border-color: $teal;
		}

		input[type="text"] {
			@include placeholder(rgba($text, .25));
			float: left;
			width: 100%;
			height: $new-menu-form-size;
			font-size: 1.2rem;
			font-weight: 600;
			line-height: $new-menu-form-size;
			margin-right: -($new-menu-form-size + 4);
			padding: 0 ($new-menu-form-size + 4) 0 10px;
			box-shadow: none;
			border: 0;

			.fonts-loaded & {
				font-family: $proxima-600;
				font-weight: 400;
			}
		}
	} // .mb-new-menu-form

	&-button {
		float: right;
		appearance: none;
		border: 0;
		background-color: $orange;
		color: #FFF;
		width: $new-menu-form-size;
		height: $new-menu-form-size;
		line-height: $new-menu-form-size;
		padding: 0;
		border-radius: 3px;

		&:hover {
			background-color: $teal;
		}

		i.icon {
			margin-top: 2px;
			height: $new-menu-form-size;
		}
	} // .mb-new-menu-button
} // .mb-new-menu
