$msg-red:    #FFCDD2;
$msg-green:  #C8E6C9;

.portal-wrapper {
	@include gradient-overlay(white-cover);
	padding: 1.5em 0;
	background: url('../images/bg-portal.jpg') no-repeat 50% 50%;
	background-size: cover;
	min-height: 75vh;

	@include media($tablet) {
		padding: 3em 0;
	}
} // .portal-wrapper

.portal-form {
	padding-bottom: 30px;

	form {
		background-color: $white;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -3px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
		border-radius: 2px;
		max-width: 400px;
		margin: 3rem auto;
		padding: 1px 1rem;

		@include media($tablet) {
			padding: 1px 2rem 1rem;

			.form-clear-right {
				padding-right: 60px !important;
				width: 100% !important;

				input {
					width: 50% !important;
				}
			}
		}

		&.select-plan {
			@extend .clearfix;
			max-width: 100%;
		}

		h3 {
			margin: 25px 0;
		}

		p {
			margin: 25px 0;
		}

		label {
			display: block;
			margin-bottom: 10px;
		}

		input[type='text'], input[type='email'], input[type='number'], input[type='password'], select {
			width: 100%;
		}

		.control-group {
			margin: 25px 0;

			label {
				font-family: $proxima-600;

				abbr {
					display: none;
				}
			}
		}

		.remember, .control-group {
			@extend .clearfix;

			label {
				float: left;
				line-height: 33px;
				font-size: 14px;
				text-transform: capitalize;
				margin-bottom: 0;
			}

			input[type='checkbox'] {
				float: left;
				height: 33px;
				line-height: 33px;
				margin-right: 7px;
			}

			.btn {
				float: right;
			}
		} // .remember

		#error_explanation {
			h2 {
				display: none;
			}

			ul {
				@extend .list-reset;
			}

			li {
				color: $error;
				border-radius: 3px;
				text-align: center;
				font-family: $proxima-600;
			}
		} // #error_explanation
	} // form

	&.form-full form {
		@extend .clearfix;
		max-width: 720px;

		@include media($tablet) {
			p {
				width: 50%;
				float: left;
				padding-right: 2rem;

				&.field-full {
					width: 100%;
				}
			}
		}
	} // &.form-full form

	&.form-inline form {
		padding-right: 0;
	}
} // .portal-form

.portal-form-extras {
	margin: 0 auto;
	max-width: 360px;
	text-align: center;

	.btn {
		margin-bottom: 15px;
	}
} // .portal-form-extras
