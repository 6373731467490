footer.main {
	clear: both;
	background-color: $black-1;
	background-image: url('../images/footer-pattern.svg');
	background-position: 50% 50%;
	background-size: 200px;
	padding: 80px 0;
}


.footer-copyright {
	color: $grey-2;
	margin-top: 3em;

	ul {
		@extend .list-reset;
		display: inline;

		li {
			display: inline;
		}
	}

	a {
		color: $grey-2;
		display: inline-block;
		margin-right: .75em;

		&:hover {
			color: $grey-1;
		}
	}

	&-info {
		display: block;
		margin-top: 1em;

		@include media($mobile-wide) {
			display: inline;
			margin: 0;
		}
	}
} // .footer-copyright

#footer_branding {
	display: none;
}
