@mixin hero-gradient($deg, $start, $end) {
	position: relative;
	z-index: 0;

	&:after {
		@extend .cover;
		background: linear-gradient($deg, $start, $end);
		content: '';
		display: block;
		z-index: -1;
		background-color: transparent;
	}
}

.content-block {
	@extend .clearfix;
	padding: 30px;

	@include media($tablet) {
		padding: 45px 0;
	}

	@include media($tablet-wide) {
		padding: 55px 0;
	}

	&.block-bg-gray {
		background-color: $grey-0;
	}
}

.block-hero {
	@include hero-gradient(65deg, rgba(0, 0, 0, .75) 0%, rgba(0, 0, 0, 0) 65%);
	color: $white;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
}

.hero-title {
	font-size: 34px;
	font-family: $proxima-700;
	text-shadow: 0 0 20px rgba(0, 0, 0, .2);

	@include media($tablet) {
		font-size: 50px;
		max-width: 540px;
	}

	@include media($tablet-wide) {
		font-size: 76px;
		max-width: 800px;
	}
} // .hero-title

.hero-title-above, .hero-title-below {
	display: block;
	font-family: $proxima-400;
}

.hero-title-above {
	font-size: 30px;

	@include media($tablet) {
		font-size: 44px;
	}

	@include media($tablet-wide) {
		font-size: 64px;
	}
}

.hero-title-below {
	font-size: 20px;
	line-height: 1.3;
	max-width: 600px;
	margin-top: 10px;

	@include media($tablet) {
		font-size: 26px;
	}

	@include media($tablet-wide) {
		font-size: 32px;
	}
}


.block-text-image {
	&-text {
		font-size: 17px;

		@include media($tablet) {
			float: left;
			width: 67%;
			padding-right: 30px;
		}

		@include media($tablet-wide) {
			font-size: 19px;
			padding-right: 50px;
		}

		h1 {
			margin-top: 0;
		}
	}

	&-image {
		@include media($tablet) {
			float: right;
			width: 33%;
		}

		img {
			width: 100%;
			height: auto;
		}
	}

	a {
		color: $orange;
	}
} // .block-text-image


.block-slider {
	padding: 0;

	.slide {
		@include hero-gradient(295deg, rgba(0, 0, 0, .8) 0%, rgba(0, 0, 0, .1) 65%);
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: cover;
		padding: 3rem;
		width: 100%;

		@include media($tablet) {
			padding: 5rem 0;
		}
	}

	.slide-content {
		color: $white;
		max-width: 480px;
		margin-left: auto;
		text-align: right;
		font-size: 19px;
		text-shadow: 0 0 15px rgba(0, 0, 0, .15);

		> :first-child {
			margin-top: 0;
		}

		> :last-child {
			margin-bottom: 0;
		}

		h1 {
			font-size: 3rem;
		}

		h2 {
			font-size: 2rem;
			font-family: $proxima-400;
			text-transform: uppercase;
		}

		p {
			color: $white;
		}

		a {
			@extend .btn;
			@extend .btn-large;
		}
	} // .slide-content

	.slick-dots {
		@extend .list-reset;
		display: none !important;
		position: absolute;
		left: 50%;
		width: 300px;
		margin-left: -150px;
		text-align: center;
		bottom: 3rem;

		@include media($tablet) {
			display: block !important;
			bottom: 2rem;
		}

		li {
			margin: 0 8px;
			display: inline-block;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			background-color: $white;
			transition: all .2s ease;
			cursor: pointer;

			&.slick-active {
				background-color: $teal;
			}

			&:hover {
				background-color: $teal;
			}
		}

		button {
			display: none;
		}
	} // .slick-dots
} // .block-slider
