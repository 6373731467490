.post {
	@extend .clearfix;
	border-bottom: 1px solid $grey;
	padding: 2em 0 1em;

	.main-col-overlap & {
		padding: 2em 2em 1em;
	}

	.post-title {
		margin: {
			top: 0;
			bottom: 1rem;
		}
	}

	.post-content li {
		line-height: 1.4;
	}

	em {
		font: {
			family: $serif;
			size: 13px;
		}
	}

	h1 em, h2 em, h3 em, strong em {
		font: {
			family: $proxima-700;
			size: inherit;
		}
	}

	.button {
		@extend .btn;
	}

	img.attachment-thumbnail {
		height: auto;
		margin-bottom: 1em;
		margin-right: 2em;
		width: 100%;

		@include media($tablet) {
			float: left;
			width: 150px;
		}
	}

	&.post-background-image {
		padding: 0;

		.post-title {
			background: $black-0 no-repeat 50% 50%;
			background-size: cover;
			padding: 2rem;
			position: relative;
			z-index: 0;

			@include media($tablet) {
				padding-top: 8rem;
			}

			&:before {
				background: rgba(0, 0, 0, .3);
				background: linear-gradient(65deg, rgba(0, 0, 0, .7) 0%, rgba(0, 0, 0, 0) 95%);
				bottom: 0;
				content: '';
				display: block;
				height: 100%;
				left: 0;
				position: absolute;
				right: 0;
				top: 0;
				width: 100%;
				z-index: -1;
			}

			a {
				@include headline-bars(h1);
				color: $white;
				display: inline-block;
				max-width: 70%;
				text-shadow: 0 1px 10px rgba(0, 0, 0, .25), 0 1px 1px rgba(0, 0, 0, .35);

				&:before, &:after {
					box-shadow: 0 1px 10px rgba(0, 0, 0, .25), 0 1px 1px rgba(0, 0, 0, .35);
					transition: all .2s ease;
				}

				&:hover {
					color: $grey-1;
				}
			}
		} // .post-title

		.post-content {
			padding: 0 2em;
		}
	} // &.post-background-image

	&.post-single {
		border-bottom: 0;
		padding: 1em;

		.post-title {
			margin-bottom: 2rem;
		}

		.post-content {
			clear: both;

			h1 {
				border-bottom: 1px solid $grey;
				margin-bottom: 1rem;
				padding-bottom: 1rem;
			}

			img {
				height: auto;
				max-width: 100%;
			}

			&-meta {
				margin: 2rem 0;
				color: #000;
			}

			.video-wrapper {
				position: relative;
				padding-bottom: 49.667%;
				padding-top: 25px;
				height: 0;
				margin: 1rem auto;
				max-width: 900px;

				@include media($tablet-wide) {
					padding-bottom: 43.667%;
				}

				.video, iframe {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			} // .video-wrapper
		} // .post-content
	} // &.post-single

	.post-edit-link {
		@extend .btn;
		margin-bottom: 2rem;
	}
} // .post

.et_monarch .et_social_inline_bottom {
	margin: 2em 0;
}

.et_social_pin_images_outer {
	display: none;
}
