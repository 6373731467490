/*
 * A column spanning the full width except for one column
 * X|████ or ████|X
 * The column floats right by default. Use .main-col-left to pull left.
 */
.main-col {
	padding-top: 1.75em;
	padding-bottom: 1.75em;

	@include media($tablet) {
		float: right;
		width: 72%;
		padding-top: 3em;
		padding-bottom: 3em;
		padding-left: 1.75em;

		&.border {
			border-left: 1px solid $grey;
		}

		&.main-col-left {
			float: left;
			padding-left: 0;
			padding-right: 1.75em;

			&.border {
				border-left: 0;
				border-right: 1px solid $grey;

				&.main-col-overlap {
					border-left: 1px solid $grey;
				}
			}
		}

		&.main-col-ads, &.main-col-media {
			width: calc(100% - 300px - 1.5rem);
		}
	}

	@include media($desktop) {
		width: 80%;
		padding-left: 3em;

		&.main-col-left {
			float: left;
			padding-left: 0;
			padding-right: 3em;
		}
	}

	&.main-col-full {
		width: 100%;
		float: none;
		border-right: 0 !important;
	}

	&.main-col-overlap {
		margin-top: -155px;
		position: relative;
		z-index: 2;
		background-color: $white;
		padding: 1.5rem;
		border-top: 4px solid $orange;

		@include media($tablet) {
			padding: 2em 2em 3em;
			margin-top: -109px;
		}

		h1.headline {
			margin-top: 0;
		}
	}
} // .main-col

/*
 * A column spanning the middle area between the left and right sidebars
 * X|███|X
 */
.middle-col {
	@extend .clearfix;
	clear: both;
	margin-top: 1.75em;
	margin-bottom: 3em;
	position: relative;

	@include media($tablet) {
		float: right;
		width: 72%;
		min-height: 300px;
		clear: none;
		margin-top: 3em;
		margin-bottom: 5em;
		padding-left: 2em;
	}

	@include media($desktop) {
		float: left;
		width: 60%;
		padding-right: 2em;
	}
} // .middle-col

.full-col {
	@extend .clearfix;
	padding: 1.75em 0;
	position: relative;

	@include media($tablet) {
		padding: 3em 0;
	}
} // .full-col

.col-wrapper {
	@extend .clearfix;

	[class$='col'] {
		min-height: 1px;

		h2, h3 {
			&:first-child {
				margin-top: 0;
			}
		}
	}
} // .col-wrapper

.second-col {
	@extend .clearfix;
	padding: 1.5em 0;

	@include media($tablet) {
		float: left;
		width: 50%;
		padding: 0;

		&:first-child {
			padding-right: 1rem;

			.gift-card & {
				padding-right: 4rem;
			}
		}

		&:last-of-type {
			padding-left: 1rem;

			.gift-card & {
				padding-left: 4rem;
			}
		}

		.block-width-60-40 &:first-child, .block-width-40-60 &:last-of-type {
			width: 60%;
		}

		.block-width-60-40 &:last-of-type, .block-width-40-60 &:first-child {
			width: 40%;
		}
	}
} // .second-col

.third-col {
	padding: 1.5em 2em;

	@include media($tablet) {
		float: left;
		width: 33.333%;
		padding-bottom: 0;
	}
} // .third-col

.fourth-col {
	padding: 1.5em 0;

	@include media($mobile-landscape) {
		float: left;
		width: 50%;
		padding-bottom: 0;
	}

	@include media($tablet) {
		width: 25%;
	}
} // .fourth-col

@include media($tablet) {
	.table {
		display: table;
		width: 100%;

		[class$='col'] {
			float: none;
			display: table-cell;
		}
	}
}

/*
 * Show arrows between columns
 */
@include media($tablet) {
	.steps-horizontal .step, .steps-horizontal > div[class$='col'] {
		float: none;
		display: table-cell;
		position: relative;

		&:not(:first-child):before, &:not(:last-of-type):after {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			bottom: auto;
		}

		/* BAR */
		&:not(:last-of-type):after {
			height: 6px;
			width: 30px;
			right: -10px;
			margin-top: -3px;
			background-color: $grey-2;
		}

		/* TRIANGLE */
		&:not(:first-child):before {
			height: 20px;
			width: 20px;
			left: 10px;
			margin-top: -10px;
			border-top: 10px solid transparent;
			border-right: 10px solid transparent;
			border-bottom: 10px solid transparent;
			border-left: 10px solid $grey-2;
		}
	}
}

.pricing-table {
	@extend .clearfix;
	max-width: 740px;
	margin: 0 auto 1rem;

	@include media($tablet) {
		margin-top: 3rem;
	}

	&.loading {
		position: relative;

		.price {
			filter: blur(6px);
			pointer-events: none;
		}

		&:after {
			content: 'Processing...';
			font-family: $proxima-700;
			font-size: 1.5rem;
			position: absolute;
			background: url('../images/loading.svg') no-repeat 50% 50%;
			width: 100%;
			min-height: 85px;
			margin-top: 150px;
		}
	}

	.select-plan & {
		max-width: 100%;
	}

	.price {
		border: 1px solid $grey-2;
		background-color: $white;
		margin-top: 3rem;
		border-radius: 2px;
		float: left;
		width: 50%;
		position: relative;

		.select-plan & {
			margin-bottom: 2rem;
		}

		&.price-best, &.year {
			margin-top: 0;
			border-color: $orange;
			box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -3px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
			margin-left: -1px;
			padding-bottom: 1rem;
		}
	}

	.price-pre-title {
		font-family: $proxima-600;
		text-transform: uppercase;
		display: block;
		color: $white;
		background-color: $orange;
		line-height: 3rem;
		height: 3rem;
	}

	$price-savings-size: 100px;

	.price-savings {
		display: block;
		position: absolute;
		z-index: 2;
		width: $price-savings-size;
		height: $price-savings-size;
		padding: 13px 0 0;
		border-radius: 50%;
		background-color: $teal;
		font-family: $proxima-600;
		font-size: 16px;
		color: $white;
		//margin-top: -($price-savings-size / 2);
		//margin-right: 10px;
		text-align: center;
		line-height: 1;
		box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, .12), 0 1px 1px 0 rgba(0, 0, 0, .24);
		bottom: -60px;
		right: -2rem;

		@include media($tablet) {
			top: -10px;
			right: -10px;
			bottom: auto;
		}

		&-amount {
			display: block;
			font-family: $proxima-900;
			font-size: 40px;
			margin: 4px 0 0;
		}
	} // .price-savings

	.price-title {
		clear: both;
		font-size: 2rem;
		margin: 2rem 0 .5rem;

		.select-plan & {
			font-size: 2rem;
		}
	}

	.price-subtitle {
		display: block;
		color: $black-0;
	}

	.price-num {
		font-size: 2.75rem;
		font-family: $proxima-700;
		display: block;
		margin: 1.5rem 0;
		color: $teal;

		@include media($tablet) {
			font-size: 4rem;

			.select-plan & {
				font-size: 3rem;
			}
		}

		&-type {
			font-size: 1rem;
			font-family: $proxima-400;
			color: $text;
			display: inline-block;
			margin-left: 2px;
		}
	} // .price-num

	.price-monthly {
		display: block;
		margin-bottom: 2rem;
		color: $black-0;
	}

	.price-select {
		input[type='radio'] {
			visibility: hidden;

			&:checked + label {
				color: $orange;
				border-color: $orange;
				box-shadow: 0 0 5px $orange;

				&:before {
					font: 900 normal normal 1.4rem/1 FontAwesome;
					content: '\f14a';
				}
			}
		}

		label {
			position: relative;
			border: 1px solid $grey-2;
			border-radius: 3px;
			margin: -1em auto 1em;
			padding: 1em;
			display: block;
			max-width: 150px;
			cursor: pointer;
			font-family: $proxima-600;
			transition: all .2s ease;
			line-height: 1.4rem;

			&:before {
				display: inline-block;
				font: normal normal normal 1.4rem/1 FontAwesome;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				content: '\f0c8';
				text-align: left;
				vertical-align: top;
				margin-right: 5px;
				width: 1em;
			}

			&:hover {
				color: $teal;
				border-color: $teal;
			}
		} // label
	} // .price-select

	a.btn.btn-large {
		margin-bottom: 2rem;
		padding-left: 1em;
		padding-right: 1em;

		@include media($tablet) {
			padding-left: 2em;
			padding-right: 2em;
		}
	}
} // .pricing-table

.gift-card-cta {
	margin: 4.5rem auto 0;
	padding: 0 75px;
	max-width: 650px;
	text-align: center;
	background: url('../images/razzle-dazzle.png') no-repeat;
	background-size: 75px auto;

	&-title {
		margin-bottom: 0;
	}
}

.radio-wrap {
	&.radio-mono label {
		padding: 7rem 1rem;
	}

	&.radio-bi label {
		padding: 2.75rem 1rem;
	}

	&.radio-quad {
		float: left;
		width: 50%;

		label {
			padding: 2.5rem 1rem;

			@include media($tablet) {
				padding: 4.1rem 1rem;
			}
		}
	}

	input[type='radio'] {
		position: absolute;
		visibility: hidden;

		+ label {
			display: block;
			position: relative;
			border: 2px solid $grey;
			border-radius: 2px;
			margin: .5rem;
			font-family: $proxima-600;
			text-align: center;
			transition: all .2s ease;
			cursor: pointer;

			&:hover {
				border: 2px solid $teal;
				background-color: transparentize($teal, .9);

				.radio-price {
					color: #FFF;
					background-color: $teal;
				}
			}
		}

		&:checked + label {
			border: 2px solid $orange;
			background-color: transparentize($orange, .9);

			.radio-price {
				color: #FFF;
				background-color: $orange;
			}
		}
	}

	.radio-price {
		position: absolute;
		top: .5rem;
		left: .5rem;
		font-family: $proxima-600;
		background-color: $grey;
		border-radius: 2px;
		padding: .2rem .35rem;
		font-size: .9rem;
		transition: all .2s ease;
	}
} // .radio-quad
