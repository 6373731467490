/*
 * HERO
 */
.hero {
	position: relative;
	z-index: 1;
	background: $black-0 no-repeat 50% 50%;
	background-size: cover;
	height: 250px;
	text-shadow: 0 1px 20px rgba(0, 0, 0, .3), 0 1px 1px rgba(0, 0, 0, .4);

	@include media($tablet) {
		height: 400px;
	}

	@include media($tablet-wide) {
		height: 500px;
	}

	&__single_headline {
		h1 {
			@include headline-bars(hero);
			color: $white;
			margin: 0;
			max-width: 80%;
			position: absolute;
			text-align: left;
			top: 50%;
			transform: translateY(-50%);
			width: 420px;

			@include media($tablet) {
				width: 650px;
			}

			&.headline-right {
				text-align: right;
				width: 100%;

				.headline-container {
					float: right;
					width: 420px;
					display: block;

					@include media($tablet) {
						width: 650px;
					}
				}
			} // h1.headline-right

			&.headline-center {
				width: 100%;
				text-align: center;

				.headline-container {
					width: 420px;
					margin-left: auto;
					margin-right: auto;
					display: block;

					@include media($tablet) {
						width: 650px;
					}
				}
			} // h1.headline-center
		}
	} // &__single_headline

	&__multiple_headlines {
		color: $white;
		height: auto;
		padding: 4rem 0;

		h1 {
			font-size: 34px;
			margin: 0;

			@include media($tablet) {
				font-size: 50px;
				max-width: 540px;
			}

			@include media($tablet-wide) {
				font-size: 76px;
				max-width: 800px;
			}

			&.headline-center {
				max-width: 100%;
				text-align: center;
			}

			&.headline-right {
				max-width: 100%;

				.headline-container {
					float: right;
					display: block;

					@include media($tablet) {
						font-size: 50px;
						max-width: 540px;
					}

					@include media($tablet-wide) {
						font-size: 76px;
						max-width: 800px;
					}
				}
			}
		}

		.hero-top-headline {
			font-size: 30px;
			margin: 0;

			@include media($tablet) {
				font-size: 44px;
			}

			@include media($tablet-wide) {
				font-size: 64px;
			}
		}

		.hero-bottom-headline {
			font-size: 20px;
			font-family: $proxima-400;
			line-height: 1.25;
			max-width: 600px;
			margin-top: 10px;
			margin-bottom: 0;
			clear: both;

			@include media($tablet) {
				font-size: 26px;
			}

			@include media($tablet-wide) {
				font-size: 32px;
			}

			&.headline-center, &.headline-right {
				max-width: 100%;
			}
		}
	} // &__multiple_headlines

	&__cta {
		height: auto;
		color: $white;
		text-shadow: none;
		padding: 5rem 1rem;

		.container {
			max-width: 960px;
		}

		h1 {
			margin: 0 0 2rem;
		}

		.hero-subtitle {
			font-size: 1.4rem;
			font-family: $proxima-700;
			margin: 0 0 2rem;
		}
	}

	&.headline-black h1, &.headline-black p {
		color: $text;
		text-shadow: none;

		&:before, &:after {
			box-shadow: none;
		}
	}

	&.headline-black.block-gradient-white_left,
	&.headline-black.block-gradient-white_right {
		border-bottom: 1px solid $grey;
	}

	&.hero-profile {
		background-image: url('../images/oamm-cover-photo.jpg');

		@include media($tablet) {
			height: 350px;
		}

		@include media($tablet-wide) {
			height: 420px;
		}

		&:before {
			display: none;
		}
	}

	&.hero-search {
		@include gradient-overlay(dark-cover);
		background-image: url('../images/bg-laptop.jpg');
		height: 180px;

		@include media($mobile-landscape) {
			height: 240px;
		}

		&:before {
			background: rgba(0, 0, 0, .4);
		}
	}

	&.hero-breadcrumbs {
		@include gradient-overlay(dark-left);
		background-image: url('../images/bg-blog.jpg');
		height: 260px;

		.container {
			padding-left: 2rem;
			padding-right: 2rem;

			@include media($desktop) {
				padding-left: 1rem;
				padding-right: 1rem;
			}
		}

		.breadcrumbs {
			margin-top: 115px;
		}
	}

	// Used on menus and recipes
	&.hero-food {
		@include media($tablet) {
			height: 350px;

			.breadcrumbs {
				margin-top: 205px;
			}
		}
	}

	.breadcrumbs {
		@extend .small;
		color: $white;
		display: inline-block;
		font-family: $proxima-700;
		height: 20px;
		line-height: 20px;
		position: relative;

		a {
			color: $white;
			text-decoration: underline;

			&:hover {
				color: $grey-1;
			}
		}

		.breadcrumb_last {
			display: none;

			@include media($tablet) {
				display: inline;
			}
		}
	}

	.hero-content {
		left: 50%;
		max-width: 580px;
		padding: 0 15px;
		position: absolute;
		top: 50%;
		transform: translateY(-51%) translateX(-50%);
		width: 100%;

		p {
			color: $white;
			font-family: $serif;
			font-size: 25px;
			font-style: italic;
			margin: 0 0 .85em;
			text-align: center;

			@include media($mobile-wide) {
				font-size: 28px;
			}

			@include media($mobile-landscape) {
				font-size: 30px;
			}
		}
	} // .hero-content

	.hero-search-form {
		position: relative;
		border-radius: 3px;

		&.focus {
			box-shadow: 0 0 8px $orange;
		}

		$hero-search-height: 46px;

		.hero-search-input {
			border-radius: 100px;
			float: left;
			font-size: 21px;
			height: $hero-search-height;
			line-height: $hero-search-height;
			margin-right: -60px;
			padding: 0 60px 0 15px;
			width: 100%;
			-webkit-appearance: none;
			box-shadow: 0 2px 3px rgba(0, 0, 0, .3);
		}

		.hero-search-submit {
			background-color: $orange;
			color: $white;
			cursor: pointer;
			float: right;
			font-size: 21px;
			height: $hero-search-height - 10;
			line-height: $hero-search-height - 10;
			margin: 5px;
			padding: 0;
			text-align: center;
			transition: all .2s ease;
			width: 50px;

			&:hover {
				background-color: $teal;
			}
		}

		.hero-search-input:focus,
		.hero-search-submit:focus {
			outline: none;
		}
	} // .hero-search-form
} // .hero
