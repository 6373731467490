.mb-recipes-list {
	margin-bottom: 2rem;

	&__media {
		@include media($tablet) {
			float: left;
			width: 100%;
			padding-right: 320px;
			margin-right: -320px;

			+ .mb-media {
				float: right;
				margin-top: 2rem;
			}
		}

		@include media($tablet) {
			width: 720px;
			padding-right: 0;
			margin-right: 0;
		}

		.mb-recipes-list-item-meal-type {
			margin-right: 0;
		}
	}

	.btn {
		border: 0;
		border-radius: 100px;
		text-shadow: none;
		box-shadow: none;
	}

	ul {
		@extend .list-reset;
	}

	&-search {
		padding: 0 1rem;

		input[type="checkbox"] {
			line-height: 32px;
			height: 32px !important;
		}

		&-input {
			outline: none;
			width: calc(100% - 2rem);
			border-radius: 16px;
			border: 1px solid $black-0;
			-webkit-box-shadow: none;
			box-shadow: none;
			color: $text;
			padding: 0 1rem;
			height: 32px;
			line-height: 30px;
			-webkit-transition: border .2s ease;
			transition: border .2s ease;

			&:focus {
				border-color: $teal;
			}
		}
	}

	input[type="checkbox"] {
		@include size(1.4rem);
		float: left;
		appearance: none;
		margin-right: .6rem;
		margin-bottom: 2rem;
		outline: none;
		border: 0;

		&:before {
			content: '\78';
			font-family: 'oamm';
			font-size: 1.4rem;
			color: $grey-2;
		}

		&:hover {
			cursor: pointer;

			&:before {
				color: $teal;
			}
		}

		&:checked {
			&:before {
				content: '\79';
				color: $orange;
			}
		}
	} // input[type="checkbox"]

	&-item {
		padding: 1rem;
		position: relative;
		clear: both;

		&:nth-child(odd) {
			background-color: rgba($grey-1, .4);
		}

		.js_favorite {
			float: right;
			color: $pink;
			font-size: 1.4rem;

			&:hover {
				animation: pulse 1s infinite;
			}
		}

		&-details {
			margin-bottom: 5px;
		}

		&-cook-type {
			@extend .small;
			color: $black-0;
			margin-right: 10px;
		}

		&-meal-type {
			position: absolute;
			bottom: 1rem;
			right: 0;
			display: inline-block;
			color: #FFF !important;
			padding: 5px 10px;
			line-height: 1;
			font-size: .8rem;
			background-color: $grey;
			border-radius: 3px;
			text-transform: uppercase;
			text-decoration: none !important;
			font-weight: 600;
			margin-right: 1rem;

			@include media($mobile-landscape) {
				float: right;
				position: relative;
				bottom: auto;
				right: auto;
			}

			.fonts-loaded & {
				font-family: $proxima-600;
				font-weight: 400;
			}

			&:hover {
				background-color: $teal !important;
				color: #FFF;
			}

			&.breakfast {
				background-color: #FFCD00;
			}

			&.lunch {
				background-color: #50B74F;
			}

			&.dinner {
				background-color: #CE3D29;
			}

			&.addon {
				background-color: #D490D4;
			}

			&.side_dish {
				background-color: $teal;
			}

			&.snack {
				background-color: #4079FF;
			}

			&.dessert {
				background-color: #7D3E11;
			}
		}

		&-author {
			color: $teal !important;
			font-family: $serif;
			font-size: .8rem;
			font-style: italic;
		}

		&-name {
			font-weight: 600;
			font-size: 1.2rem;
			line-height: 1.2;
			display: block;
			width: 80%;

			@include media($mobile-landscape) {
				display: inline-block;
				width: auto;
			}

			.fonts-loaded & {
				font-family: $proxima-600;
				font-weight: 400;
			}
		}

		&-delete {
			color: $error;
			font-weight: 600;
			display: block;
			margin-top: 5px;
			text-decoration: none !important;

			@include media($tablet) {
				color: $grey-2;
				font-size: 1.4rem;
				margin-top: -1.35rem;
				float: right;
				margin-right: 1rem;

				&-text {
					display: none;
				}
			}

			&:hover {
				color: $teal;

				@include media($tablet) {
					color: $error;
				}
			}

			.fonts-loaded & {
				font-family: $proxima-600;
				font-weight: 400;
			}
		} // a.js_delete-recipe


		.fa-external-link {
			font-size: .8rem;
			color: $black-0;
			margin-top: 6px;
		}
	}
} // .mb-recipes-list
