.swal-text, .swal-footer {
	text-align: center;
}

.swal-button--confirm {
	background-color: $teal;

	&:hover {
		background-color: $teal-2 !important;
	}
}

.swal-button--danger {
	background-color: $error;

	&:hover {
		background-color: darken($error, 10%) !important;
	}
}

.swal-button--cancel, .swal-button--support {
	color: $text;
	background-color: inherit;

	&:hover {
		background-color: $grey-1 !important;
	}
}
