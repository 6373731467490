/***
 * Typography:
 */

h1, h2, h3, h4, h5, h6, strong, b {
	font-family: $helvetica;
	font-weight: 700;
	font-display: swap;
	letter-spacing: -.65px;

	.fonts-loaded & {
		font-family: $proxima-700;
		font-weight: 400;
		letter-spacing: normal;
	}
}

/**
 * Headlines:
 *
 *     @example
 *     h1 h1 Headline
 *     h2 h2 Headline
 *     h3 h3 Headline
 */

h1, h2 {
	.text-center &.headline {
		@extend .headline-center;
	}

	.text-right &.headline {
		@extend .headline-right;
	}

	&.headline-large {
		font-size: 2.5rem;
		text-transform: uppercase;

		@include media($tablet) {
			font-size: 3rem;
		}
	}

	&.razzle-dazzle {
		font-family: $proxima-900;
		font-size: 2.65rem;
		letter-spacing: 1px;
		text-transform: uppercase;

		@include media($tablet) {
			position: relative;
			font-size: 4rem;
			width: 50%;
			margin-left: auto;
			margin-right: auto;

			&:before {
				content: '';
				display: block;
				position: absolute;
				width: 117px;
				height: 98px;
				background: url('../images/razzle-dazzle.png') no-repeat;
				margin-top: -70px;
			}
		}

		+ .sub-title {
			margin-top: -2rem;
			font-size: 30px;
		}
	}

	.headline-label {
		@extend .small;
		display: block;
		color: $teal;
	}
}

h1 {
	font-size: 30px;
	line-height: 1.2;
	margin: .85em 0;

	@include media($tablet-wide) {
		font-size: 38px;
	}

	&.headline {
		@include headline-bars(h1);
	}
}

h2 {
	font-size: 22px;
	line-height: 1.2;
	letter-spacing: .025em;
	margin: .85em 0;

	@include media($tablet-wide) {
		font-size: 26px;
	}

	&.headline {
		@include headline-bars(h2);
	}
}

h3 {
	font-size: 18px;
	line-height: 1.2;

	@include media($tablet-wide) {
		font-size: 20px;
	}
}

@include media($tablet-down) {
	.headline-mobile {
		background-color: $text;
		color: $white;
		font-family: $proxima-400;
		font-size: 1em !important;
		text-transform: uppercase;
		margin: 1em 0 0;
		padding: .65em 1em !important;

		&:before, &:after {
			display: none !important;
		}
	}
}

.sub-title {
	line-height: 1.4;
	margin-bottom: 2rem;

	@include media($tablet) {
		font-size: 1.2rem;
	}

	@include media(new-breakpoint(max-width 480px 4)) {
		&-hidden {
			position: relative;
			max-height: 5rem;
			overflow-y: hidden;
			margin-bottom: 0;

			&:after {
				@include gradient(rgba(255, 255, 255, 0), #FFF);
				content: '';
				display: block;
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 3rem;
			}

			+ .sub-title-more {
				display: block;
			}
		}
	}

	&-more {
		display: none;
		text-align: center;

		a {
			@extend .small;
			display: inline-block;
			color: #FFF;
			background-color: $teal;
			line-height: 2em;
			text-decoration: none !important;
			padding: 0 1rem;
			border-radius: 100px;
		}
	}

	&-edit {
		display: none;
		position: relative;
		padding: 1rem;
		background-color: #FFF;
		box-shadow: 0 0 0 1px rgba($teal, .75), 0 3px 1px -2px rgba($teal, .5), 0 0 8px 0 rgba($teal, .75);
		border-radius: 3px;

		&.loading {
			background-color: transparent;

			textarea {
				background: url('../images/loading.svg') no-repeat 50% 50%;
			}
		}

		&-buttons {
			@include media($tablet) {
				position: absolute;
				right: 1rem;
				bottom: 1rem;
			}

			.btn {
				border: 0;
				box-shadow: none;
				font-size: 1rem;
			}
		}
	} // .sub-title-edit

	textarea.sub-title-edit-textarea {
		width: 100%;
		border: 1px solid transparent; // Fixes a weird bug when resizing the text field to fit the text
		box-shadow: none;
		padding: 0;
		overflow: auto;
		resize: none;
		line-height: 1.6;
	}

	.sub-title-edit-button {
		color: $grey-2;
		font-family: $proxima-600;
		font-size: 1rem;
		line-height: 1rem;
		text-decoration: none;

		&:hover {
			color: $orange;
		}

		i {
			float: left;
			margin-top: 6px;
		}
	}
} // .sub-title

p {
	line-height: 1.35;
}

.text-center, .headline-center {
	text-align: center;
}

.text-right, .headline-right {
	text-align: right;
}

.text-white {
	color: $white;
}

.small, small {
	font-size: .8rem;
	font-weight: 600;
	font-style: normal;
	text-transform: uppercase;

	.fonts-loaded & {
		font-family: $proxima-600;
		font-weight: 400;
	}
}

.info {
	border: 1px solid $grey;
	border-radius: 2px;
	background-color: $grey-0;
	font-family: $serif;
	font-style: italic;
	padding: 10px;
	margin-top: -5px;
	margin-bottom: ($default-font-size - 5px);

	@include media($tablet) {
		background-color: #FFF;
	}

	&:before {
		float: right;
		content: '\f05a';
		line-height: 1em;
		text-align: center;
		display: inline-block;
		margin-top: 3px;
		margin-left: 3px;
		color: $teal;
		font: normal normal 900 14px/1 FontAwesome;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
} // .info

.warning {
	margin-bottom: 2rem;
	border: 1px solid $grey;
	border-radius: 2px;
	background-color: #FFFDEF;
	padding: 1rem;
	line-height: 1.35;
}

.notice {
	background: #FFF;
	border-left: 3px solid #FFDE71;
	box-shadow: 0 1px 3px 0 rgba(#000, .05), 0 3px 1px -2px rgba(#000, .05), 0 1px 4px 0 rgba(#000, .05);
	padding: 1rem;
	margin-bottom: 2rem;
	line-height: 1.35;
}

.steps-circled {
	@extend .list-reset;
	margin: 1rem 0;
	counter-reset: step;

	@include media($tablet) {
		padding: 0 3rem;
	}

	li {
		text-align: left;
		position: relative;
		padding-bottom: 2rem;
		padding-left: 55px;

		&:before {
			counter-increment: step;
			content: counter(step);
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			display: block;
			width: 40px;
			height: 40px;
			color: $teal;
			font-size: 1.5rem;
			line-height: 38px;
			font-family: $proxima-700;
			text-align: center;
			border-radius: 50%;
			border: 2px solid $black;
			background-color: $white;
		}

		&:after {
			position: absolute;
			content: '';
			display: block;
			top: 40px;
			left: 19px;
			width: 2px;
			bottom: 0;
			background-color: $black;
		}

		&:last-of-type {
			padding-bottom: 0;

			&:after {
				display: none;
			}
		}

		h1, h2, h3, strong {
			font-size: 22px;
			line-height: 40px;
			margin: 0;

			@include media($tablet) {
				font-size: 26px;
			}
		}
	}
} // .steps-circled

.aligncenter {
	margin: 0 auto;
	display: block;
}

.alignleft {
	float: left;
	margin-right: 1rem;
}

.alignright {
	float: right;
	margin-left: 1rem;
}

.inline-testimonial {
	clear: both;
	margin: 2rem 0;

	p {
		font-family: $serif;
		font-size: 1.2rem !important;
		font-style: italic;

		+ p {
			padding-left: 12rem;
		}
	}

	img {
		border-radius: 10px;
		margin-bottom: 2rem;
		margin-right: 2rem;
	}

	strong {
		font-family: $proxima-700;
		font-size: 1.4rem;
		font-style: normal;
	}
} // .inline-testimonial

.dropcap:first-letter {
	font-size: 4.4rem;
	font-family: $proxima-700;
	line-height: 1;
	float: left;
	margin-top: -1px;
	margin-right: 5px;
	margin-bottom: -10px;
}

.wp-caption {
	width: 100% !important;
}
