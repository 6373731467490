.comments {
	border-top: 1px solid $border-color;
	clear: both;

	&__media {
		@include media($tablet) {
			width: 50%;
		}

		@include media($desktop) {
			width: 720px;
		}
	}

	&-header {
		border-bottom: 1px solid $grey;
		margin-bottom: 1rem;
	}

	&-title {
		display: inline-block;
		text-transform: uppercase;
		border-bottom: 1px solid $orange;
		margin: 0;
		padding: 1rem 0;
		margin-bottom: -1px;
		line-height: 1;
	}

	&-respond {
		float: right;
		display: inline-block;
		padding: 1rem 0;
		line-height: 1;
	}

	&-list {
		@extend .list-reset;
	}

	ol.children {
		list-style: none;
		padding: 0;

		.comment-body {
			padding-left: 32px + $default-font-size;
		}

		ol.children .comment-body {
			padding-left: (32px + $default-font-size) * 2;
		}

		ol.children ol.children .comment-body {
			padding-left: (32px + $default-font-size) * 3;
		}

		ol.children ol.children ol.children .comment-body {
			padding-left: (32px + $default-font-size) * 4;
		}
	}

	.comment {
		padding-top: 1rem;

		a {
			text-decoration: underline;
		}

		&-body {
			border-bottom: 1px solid $grey-1;
		}

		&-author, &-metadata, .edit-link {
			display: inline;
			margin-left: 1rem;
		}

		&-metadata a {
			display: none;
			color: $grey-2;

			@include media($tablet-wide) {
				display: inline;
			}

			&:hover {
				color: $text;
			}
		}

		.says {
			display: none;
		}

		.avatar {
			float: left;
		}

		&-content, .reply {
			padding-left: 32px + $default-font-size;
		}

		.reply {
			padding-bottom: 1rem;
		}
	} // .comment

	.comment-respond {
		margin-top: 2rem;
	}

	.comment-form {
		@extend .input-row;
		border-top: 0;
		padding: 0;

		&:last-of-type {
			border-bottom: 0;
		}
	}
} // .comments
