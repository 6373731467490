header.main {
	position: relative;
	background-color: #FFF;
	border-top: 3px solid $teal;
	border-bottom: 1px solid $grey-1;

	&:not(:first-of-type) {
		display: none;
	}

	.container {
		height: $logo-height + ($header-padding * 2);
		padding: $header-padding 1rem;

		@include media($desktop) {
			padding: $header-padding 2rem;
		}
	}

	.page &, .recipe-single &, .menu-single & {
		box-shadow: none;
	}

	.vip & {
		border-top-color: gold;
	}

	+ .hero.block-gradient-dark_left {
		z-index: 0;
	}

	.success-path-stage {
		background-color: $teal;
		border-radius: 100px;
		line-height: 22px;
		margin: 13px 7px 13px 0 !important;
		width: 22px;
		text-align: center;
		color: $white !important;
		z-index: 1;
		float: left;
	}
} // header.main

.oamm-cookies {
	color: $white;
	background-color: $black-0;
	position: relative;
	line-height: 1.3;
	margin-bottom: -1px;

	.container {
		height: auto !important;
	}

	a {
		color: $white;
		text-decoration: underline;

		&:hover {
			color: $grey;
		}
	}

	&-close {
		display: block;

		@include media($desktop) {
			display: inline-block;
			margin-left: 1rem;
		}
	}
} // .oamm-cookies

a.header {
	&-logo {
		@include size($logo-width, $logo-height);
		display: block;
		margin: 0 auto;

		@include media(new-breakpoint(min-width 545px 4)) {
			position: absolute;
			left: 1rem;
		}

		@include media($desktop) {
			left: 2rem;
		}

		.logo-image path {
			transition: all .2s ease;
		}

		&:hover .logo-image path {
			fill: $orange;
		}
	} // .header-logo

	&-back, &-search, &-stage {
		position: absolute;
		left: 1rem;
		height: $logo-height;
		line-height: $logo-height;
		font-size: 1.4rem;
		color: $text;

		@include media(new-breakpoint(min-width 545px 4)) {
			display: none;
		}

		.icon {
			float: left;
			margin-top: 5px;
		}
	} // .header-back, .header-search

	&-back {
		+ .header-mobile-menu {
			display: none;

			@include media(new-breakpoint(min-width 545px 4)) {
				display: block;
			}
		}
	} // .header-back

	&-search {
		left: auto;
		right: 1rem;

		.icon {
			margin-top: 4px;
		}
	} // .header-search

	header.main &-stage {
		left: auto;
		right: 1rem;
		display: block !important;
		height: 22px;
		font-size: 1rem;
		margin: 14px 0 !important;

		@include media(new-breakpoint(min-width 545px 4)) {
			right: 3.5rem;
		}

		@include media(new-breakpoint(min-width 960px 12)) {
			display: none !important;
		}
	}

	&-back + &-mobile-menu + &-stage {
		right: 3.5rem;
	}

	&-mobile-menu {
		color: $text;
		display: block;
		height: $logo-height;
		outline: none;
		position: absolute;
		cursor: pointer;

		@include media(new-breakpoint(min-width 545px 4)) {
			right: 1rem;
		}

		@include media(new-breakpoint(min-width 960px 12)) {
			display: none !important;
		}

		.fa, .fas {
			font-size: 30px;
			line-height: $logo-height;
			float: left;
		}
	} // .header-mobile-menu
} // .header
