.prep-list {
	@extend .list-reset;
	@extend .clearfix;

	label {
		float: left;
		width: 100%;
	}

	input[type="checkbox"] {
		height: 1.4rem;
		width: 1.4rem;
		vertical-align: bottom;
		appearance: none;
		margin-right: .2rem;
		outline: none;
		border: 0;

		&:before {
			content: '\78';
			font-family: 'oamm';
			font-size: 1.4rem;
			color: #AAA;
		}

		&:hover {
			cursor: pointer;
		}

		&:focus:before {
			color: $teal;
		}

		&:checked {
			&:before {
				content: '\79';
				color: $teal;
			}

			~ span strong {
				color: $teal;
				text-decoration: line-through;
			}
		}
	} // input[type="checkbox"]

	&-name {
		color: $black-0;
		cursor: pointer;

		strong {
			color: $text;
			margin-right: 10px;
		}
	}

	&-recipe {
		display: block;
		margin: 5px 0 0 1.9rem;
		line-height: 1.6;

		@include media($tablet) {
			float: right;
			margin: 0;
		}

		.detailed-resource-list {
			@extend .list-reset;
		}
	}

	&-desc {
		margin-left: 1.9rem;
	}
} // .prep-list
