.mb-hero {
	position: relative;
	z-index: 1;
	margin: 0 -1rem;
	padding: 1rem;
	min-height: 210px;
	color: #FFF;
	background-image: url('../images/bg-custom-menu.png');
	background-size: cover;
	background-position: 50% 50%;
	text-shadow: 0 1px 20px rgba(#000, .3), 0 1px 1px rgba(#000, .4);

	@include media(new-breakpoint(min-width 900px 8)) {
		margin: 0;
		padding: 2rem;
		border-radius: 5px;
		min-height: 400px;
	}

	&:after {
		@extend .cover;
		content: '';
		background-image: linear-gradient(0deg, rgba(#000, .4) 0%, transparent 45%);

		@include media($tablet) {
			background-image: linear-gradient(0deg, rgba(#000, .68) 0%, transparent 52%);
			border-radius: 5px;
		}
	}

	* {
		position: relative;
		z-index: 1;
	}

	a {
		color: $white;
		text-decoration: underline;

		&:hover {
			color: $orange;
		}
	}

	&-back {
		display: none;
		font-weight: 700;
		text-transform: uppercase;

		@include media($tablet) {
			display: inline-block;
		}

		.fonts-loaded & {
			font-family: $proxima-700;
			font-weight: 400;
		}

		.fa, .icon {
			float: left;
			margin-right: 6px;
		}
	} // .mb-hero-back

	&-title {
		display: none;
		position: absolute;
		left: 1rem;
		bottom: 1rem;
		width: 100%;
		max-width: 525px;

		@include media($tablet) {
			display: block;
		}

		@include media(new-breakpoint(min-width 900px 8)) {
			left: 2rem;
			bottom: 2rem;
		}

		&-text {
			margin-right: 10px;
		}
	} // .mb-hero-title

	&-breadcrumbs {
		@extend .small;
		display: none;

		@include media($tablet) {
			display: block;
		}
	} // .mb-hero-breadcrumbs

	&-cta {
		position: absolute;
		right: 1rem;
		bottom: 1rem;
		border-radius: 100px;
		background-color: $orange;
		height: 32px;
		font-weight: 700;
		line-height: 32px;
		text-decoration: none !important;
		text-shadow: none;
		text-transform: uppercase;
		padding: 0 1rem;

		@include media($tablet) {
			right: 2rem;
			bottom: 2rem;
		}

		&:hover {
			color: #FFF !important;
			background-color: $teal !important;
		}

		.fonts-loaded & {
			font-family: $proxima-700;
			font-weight: 400;
		}

		&.error {
			background-color: $error;
		}
	} // .mb-hero-cta

	&-print {
		display: none;
		font-weight: 700;
		text-transform: uppercase;
		position: absolute;
		right: 1rem;
		bottom: 1rem;

		@include media($tablet) {
			display: inline-block;
		}

		@include media(new-breakpoint(min-width 900px 8)) {
			right: 2rem;
			bottom: 2rem;
		}

		.fonts-loaded & {
			font-family: $proxima-700;
			font-weight: 400;
		}

		.fa, .icon {
			float: left;
			margin-top: 2px;
			margin-right: 6px;

			.fonts-loaded & {
				margin-top: 0;
			}
		}
	} // .mb-hero-back

	.oamm-social {
		@include media($tablet) {
			display: none;
		}
	}

	&-locked {
		display: inline-block;
		text-transform: uppercase;
		float: right;
		font-family: $proxima-600;
		background-color: $teal-1;
		text-shadow: none;
		padding: .6rem 1rem;
		border-radius: 5px;
		box-shadow: 0 1px 20px rgba(#000, .3), 0 1px 1px rgba(#000, .4);

		i {
			display: inline-block;
			margin-right: 4px;
		}
	}

	h1, &-title-text, &-edit-textarea {
		margin-top: 1rem;
		margin-bottom: 0;
		font-size: 1.4rem;
		font-weight: 700;
		display: inline-block;

		@include media($tablet-wide) {
			font-size: 2.4rem;
			line-height: 1.2;
		}

		.fonts-loaded & {
			font-family: $proxima-700;
			font-weight: 400;
		}
	}

	textarea.mb-hero-edit-textarea {
		border: 0;
		border-bottom: 1px solid #FFF;
		box-shadow: none;
		background-color: transparent;
		margin-bottom: -1px;
		padding: 0;
		display: block;
		width: 100%;
		color: #FFF;
		font-weight: 700;
		font-size: 1.4rem;
		line-height: 1.2;
		resize: none;

		@include media($desktop) {
			font-size: 2.4rem;
		}

		.fonts-loaded & {
			font-family: $proxima-700;
			font-weight: 400;
		}
	}

	&-edit-buttons {
		margin-top: 1rem;

		@include media($desktop) {
			position: absolute;
			top: -.5rem;
			left: 103%;
			width: 120px;
		}

		.btn {
			border: 0;
			box-shadow: none;
		}
	} // .mb-hero-edit-buttons

	&-title-edit-button {
		display: block;
		font-size: 1rem;
		opacity: .5;
		margin-top: 1rem;
		margin-left: 3px;
		text-decoration: none !important;

		@include media($desktop) {
			display: inline-block;
			margin: 0;
		}

		&:hover {
			color: #FFF !important;
			opacity: 1;
		}
	}

	&-edit {
		display: none;
	}
} // .mb-hero
