.recipe-card {
	@extend .clearfix;
	position: relative;
	padding: 0;

	&-title {
		margin-top: 1rem;
		width: 75%;

		+ .mb-print {
			margin-top: -3.6rem;
		}
	}

	&-items {
		@include media($tablet) {
			float: left;
			width: 30%;
		}

		h4 {
			margin: 1.2rem 0 8px;
		}

		&-list {
			@extend .list-reset;

			li {
				margin-bottom: 8px;
			}
		}
	}

	&-instructions {
		@include media($tablet) {
			float: right;
			width: 66.66667%;
		}

		&-list {
			margin: 1rem 0;
			padding-left: 1.2rem;
			line-height: 1.3;

			li {
				margin-bottom: 5px;
			}
		}
	}
} // .recipe-card
