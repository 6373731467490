.input-group {
	@extend .clearfix;

	input, button {
		float: left;
	}

	input:not([type="submit"]) {
		padding-right: 110px;
		width: 100%;
	}

	[type="submit"] {
		margin-left: -110px;
		width: 110px;
	}

	&.input-group-small {
		input[type="text"], input[type="search"] {
			height: 40px;
			padding-left: 10px;
			padding-right: 42px;
		}

		[type="submit"] {
			border-radius: 0 2px 2px 0;
			height: 40px;
			margin-left: -42px;
			padding-left: 0;
			padding-right: 0;
			width: 42px;
		}
	}
} // .input-group
