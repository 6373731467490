.block-video {
	.video-wrapper {
		position: relative;
		padding-bottom: 49.667%;
		padding-top: 25px;
		height: 0;
		margin: 1rem auto;
		max-width: 900px;

		@include media($tablet-wide) {
			padding-bottom: 43.667%;
		}

		.video, iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
} // .block-video
