@charset 'UTF-8';

/* @font-face {
	font-family: 'ProximaNova-Thin';
	src: url('../fonts/ProximaNova-Thin-webfont.woff2') format('woff2'),
	     url('../fonts/ProximaNova-Thin-webfont.woff') format('woff'),
	     url('../fonts/ProximaNova-Thin-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
} */

/* @font-face {
	font-family: 'ProximaNova-Light';
	src: url('../fonts/ProximaNova-Light-webfont.woff2') format('woff2'),
	     url('../fonts/ProximaNova-Light-webfont.woff') format('woff'),
	     url('../fonts/ProximaNova-Light-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
} */

@font-face {
	font-family: 'Proxima Nova Regular';
	src: local('Proxima Nova Regular'),
	     local('ProximaNova-Reg'),
	     url('../fonts/ProximaNova-Reg-webfont.woff2') format('woff2'),
	     url('../fonts/ProximaNova-Reg-webfont.woff') format('woff'),
	     url('../fonts/ProximaNova-Reg-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova Semibold';
	src: url('../fonts/ProximaNova-Sbold-webfont.woff2') format('woff2'),
	     url('../fonts/ProximaNova-Sbold-webfont.woff') format('woff'),
	     url('../fonts/ProximaNova-Sbold-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova Bold';
	src: local('Proxima Nova Bold'),
	     local('ProximaNova-Bold'),
	     url('../fonts/ProximaNova-Bold-webfont.woff2') format('woff2'),
	     url('../fonts/ProximaNova-Bold-webfont.woff') format('woff'),
	     url('../fonts/ProximaNova-Bold-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

/* @font-face {
	font-family: 'ProximaNova-Xbold';
	src: url('../fonts/ProximaNova-Xbold-webfont.woff2') format('woff2'),
	     url('../fonts/ProximaNova-Xbold-webfont.woff') format('woff'),
	     url('../fonts/ProximaNova-Xbold-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
} */

@font-face {
	font-family: 'Proxima Nova Black';
	src: local('Proxima Nova Black'),
	     local('ProximaNova-Black'),
	     url('../fonts/ProximaNova-Black-webfont.woff2') format('woff2'),
	     url('../fonts/ProximaNova-Black-webfont.woff') format('woff'),
	     url('../fonts/ProximaNova-Black-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}


@font-face {
	font-family: 'oamm';
	src: url('../fonts/oamm.woff?v=2018.05.08') format('woff'),
	     url('../fonts/oamm.ttf?v=2018.05.08') format('truetype');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
	display: inline-block;
	height: 1em;
	vertical-align: middle;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
	font-family: 'oamm' !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-cutting-board:before {
	content: '\61';
}

.icon-frying-pan:before {
	content: '\62';
}

.icon-recipe-card:before {
	content: '\63';
}

.icon-serve:before {
	content: '\64';
}

.icon-shopping-cart:before {
	content: '\65';
}

.icon-snowflake:before {
	content: '\66';
}

.icon-bake:before {
	content: '\67';
}

.icon-meal:before {
	content: '\68';
}

.icon-slow-cooker:before {
	content: '\6a';
}

.icon-freezer:before {
	content: '\69';
}

.icon-list-ordered:before {
	content: '\6c';
}

.icon-list-unordered:before {
	content: '\4f';
}

.icon-cutlery:before {
	content: '\6d';
}

.icon-search:before {
	content: '\6e';
}

.icon-heart-o:before {
	content: '\6f';
}

.icon-heart:before {
	content: '\70';
}

.icon-star-o:before {
	content: '\71';
}

.icon-star:before {
	content: '\72';
}

.icon-arrow-left:before {
	content: '\73';
}

.icon-arrow-right:before {
	content: '\4e';
}

.icon-close:before {
	content: '\74';
}

.icon-circle-o:before {
	content: '\76';
}

.icon-circle:before {
	content: '\77';
}

.icon-square:before {
	content: '\78';
}

.icon-square-check:before {
	content: '\79';
}

.icon-certificate:before {
	content: '\6b';
}

.icon-cook:before {
	content: '\7a';
}

.icon-grill:before {
	content: '\41';
}

.icon-pot:before {
	content: '\42';
}

.icon-spoon-knife:before {
	content: '\43';
}

.icon-stovetop:before {
	content: '\44';
}

.icon-easy-assembly:before {
	content: '\45';
}

.icon-waffle-iron:before {
	content: '\46';
}

.icon-griddle:before {
	content: '\47';
}

.icon-clock:before {
	content: '\49';
}

.icon-settings:before {
	content: '\75';
}

.icon-question-circle:before {
	content: '\48';
}

.icon-mail:before {
	content: '\4a';
}

.icon-sign-out:before {
	content: '\4b';
}

.icon-prog-pressure-cooker:before {
	content: '\4d';
}


//$proxima-200: 'ProximaNova-Thin', Helvetica, Arial, sans-serif;
//$proxima-300: 'ProximaNova-Light', Helvetica, Arial, sans-serif;
$proxima-400: 'Proxima Nova Regular', Helvetica, Arial, sans-serif;
$proxima-600: 'Proxima Nova Semibold', Helvetica, Arial, sans-serif;
$proxima-700: 'Proxima Nova Bold', Helvetica, Arial, sans-serif;
//$proxima-800: 'ProximaNova-Xbold', Helvetica, Arial, sans-serif;
$proxima-900: 'Proxima Nova Black', Helvetica, Arial, sans-serif;

$helvetica: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, 'Helvetica Neue', Helvetica, Arial, sans-serif;

$serif: Georgia, 'Times New Roman', serif;
