.sidebar-widget {
	margin-top: 2em;

	@include media($desktop) {
		margin-top: 3em;
	}

	&.fa {
		font-family: $proxima-400;
		width: 100%;

		&:before {
			color: $teal;
			font-family: FontAwesome;
			font-size: 2em;
		}
	}

	&.fixed {
		position: fixed;
		top: 0;

		h2 {
			margin-top: 0;
		}
	}

	.widget-title {
		@include headline-bars(h2);
		display: block;
		margin-bottom: 1em;
	}

	ul:not(.widget-signup-features) {
		@extend .list-reset;
		@include spacing(1.35em 0, 0);
	}

	ul.children {
		margin: 0;
		margin-left: 1rem;
	}

	li {
		line-height: 1.4;

		&.current a {
			color: $teal;
		}
	}
} // .sidebar-widget

.widget-list {
	margin-bottom: 3em;

	ul {
		border-bottom: 1px solid $grey;
	}

	li {
		border-top: 1px solid $grey;
		margin-bottom: 0;
		padding: .75em 0;

		a {
			color: $text;
			display: block;
			font-family: $proxima-700;
			position: relative;
			text-decoration: none;
			padding-right: .65em;

			&:hover {
				color: $teal;

				&:after {
					border-left-color: $teal;
				}
			}

			&:before, &:after {
				content: '';
				display: block;
				height: 12px;
				margin-top: -6px;
				position: absolute;
				top: 50%;
				width: 14px;
			}

			&:before {
				border-bottom: 6px solid transparent;
				border-left: 7px solid $white;
				border-right: 7px solid transparent;
				border-top: 6px solid transparent;
				right: -5px;
				z-index: 2;
			}

			&:after {
				border-bottom: 6px solid transparent;
				border-left: 7px solid $orange;
				border-right: 7px solid transparent;
				border-top: 6px solid transparent;
				right: -7px;
				transition: all .2s ease;
			}
		}

		.tags {
			display: block;
			font-family: $serif;
			font-size: 13px;
			font-weight: 400;
		}
	}
} // .widget-list

.widget-live-chat {
	background-color: $grey-0;
	border: 1px solid $grey-1;
	padding: 1.25em;

	p {
		margin: 1em 0;
	}
}

.widget-menus {
	margin-bottom: 2em;

	.menu-card {
		margin-right: 0;
		width: 100%;
	}
}

.widget-categories {
	li {
		margin-bottom: 5px;
	}

	.current-cat > a {
		color: $text;
	}
}

.widget-signup {
	margin-bottom: 2em;
}

.widget-signup-features {
	padding-left: 1.5rem;

	li {
		margin-bottom: 5px;
	}
}
