.profile-form form {
	clear: both;

	.control-group {
		@extend .form-row;

		.control-label {
			@extend .form-label;
		}

		.controls {
			@extend .form-input;
		}
	}
}
