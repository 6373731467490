/* /////////////////////////////////////
// COLORS
///////////////////////////////////// */

$white:    #FFF;

$orange-1: #FF7C00; // 1 - Primary Main
$orange-2: #EB7100; // 2 - Primary Dark
$orange:   $orange-1;

$teal-0:   #C3EEF4; // 0 - Primary Light
$teal-1:   #197582; // 1 - Primary Main
$teal-2:   #15656F; // 2 - Primary Dark
$teal:     $teal-1;

$grey-0:   #F7F8F8; // 0 - Primary Light
$grey-1:   #E8EDF0; // 1 - Primary Main
$grey-2:   #AAA;    // 2 - Primary Dark
$grey:     $grey-1;

$black-0:  #6C6C6C; // 0 - Primary Light
$black-1:  #4A4A4A; // 1 - Primary Main
$black-2:  #404040; // 2 - Primary Dark
$black:    $black-1;

$error:    #FF3C0D;
$success:  #4CAF50;
$text:     $black;

$pink:     #F48FB1;

$border-color: darken($grey-1, 7%);


/* /////////////////////////////////////
// FONTS
///////////////////////////////////// */

$default-font-size: 15px;


/* /////////////////////////////////////
// BREAKPOINTS
///////////////////////////////////// */

$mobile-only:       new-breakpoint(max-width 480px 4);
$mobile-wide:       new-breakpoint(min-width 370px 4);
$mobile-landscape:  new-breakpoint(min-width 480px 4);
$tablet-down:       new-breakpoint(max-width 767px 8);
$tablet:            new-breakpoint(min-width 768px 8);
$tablet-wide:       new-breakpoint(min-width 960px 12);
$desktop:           new-breakpoint(min-width 1100px 12);
$desktop-wide:      new-breakpoint(min-width 1720px 12);
$desktop-very-wide: new-breakpoint(min-width 2000px 12);


/* /////////////////////////////////////
// HEADER
///////////////////////////////////// */

$logo-height: 50px;
$logo-width:  90px;
$header-padding: 10px;


/* /////////////////////////////////////
// MENU CARD DEFAULTS
///////////////////////////////////// */

$card-font-size:     20px;
$card-line-height:   1.25;
$card-lines-to-show: 3;
