.label-sheet {
	margin: 0 auto;

	@include media($tablet) {
		width: 8.5in;
		padding: .5in .25in;
	}

	.label {
		margin: 2rem auto;
		padding: 2rem;
		background-color: #FFF;
		box-shadow: 0 1px 3px 0 rgba(#000, .05), 0 3px 1px -2px rgba(#000, .05), 0 1px 4px 0 rgba(#000, .05);
		border-radius: .2in;
		overflow: hidden;
		position: relative;
		font-size: .95rem;
		min-height: 5in;
		max-width: 3.5in;

		@include media($tablet) {
			float: left;
			width: 3.5in;
			height: 5in;
			margin: 0 .25in;
			padding: .2in;
		}

		&:nth-child(3n+4), &:nth-child(4n+4) {
			margin-bottom: .5in;
		}

		&-logo {
			float: left;
			width: 25%;
			height: auto;
			margin-bottom: 1rem;
		}

		&-title {
			margin: 0;
			font-size: 1.3rem;
			line-height: 1.15;
			float: right;
			width: 70%;
			margin-bottom: 1rem;
		}

		&-instructions {
			clear: both;
		}

		&-serving-day-ingredients {
			position: absolute;
			z-index: 2;
			bottom: .2in;
			left: .2in;
			right: .2in;
			background-color: $grey-0;
			border: 1px solid $grey-1;
			padding: 10px;

			&:before {
				@include gradient(rgba(#FFF, 0), rgba(#FFF, 1));
				content: '';
				display: block;
				height: 30px;
				position: absolute;
				top: 0;
				left: -1px;
				right: -1px;
				margin-top: -31px;
			}

			&:after {
				background-color: #FFF;
				content: '';
				display: block;
				height: .2in;
				position: absolute;
				bottom: -1px;
				left: -1px;
				right: -1px;
				margin-bottom: -.2in;
			}

			&-list {
				margin: 10px 0 0;
				padding: 0 0 0 1.5rem;
			}
		}
	} // .label
} // .label-sheet
