.notification-bar {
	position: fixed;
	z-index: 999999;
	width: 100%;
	padding: 1.2rem;
	top: 0;
	left: 0;
	color: #FFF;
	background-color: $success;
	text-align: center;
	font-weight: 600;
	box-shadow: 0 0 20px rgba(#000, .8);

	@include media($tablet) {
		top: 1rem;
		right: 1rem;
		width: auto;
		max-width: 380px;
		left: auto;
		text-align: left;
		border-radius: 3px;
		font-size: 1.2rem;
	}

	.fonts-loaded & {
		font-family: $proxima-600;
		font-weight: 400;
	}

	&.error {
		background-color: $error;
	}

	a {
		color: #FFF;
		text-decoration: underline;
	}
} // .notification-bar
