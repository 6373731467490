* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html {
	font-size: $default-font-size !important;
}

body {
	color: $black;
	font: 400 #{$default-font-size} / 1 $helvetica;
	font-family: $helvetica;
	background-color: $white;

	.fonts-loaded & {
		font-family: $proxima-400;
	}
}

a, a:visited {
	color: $orange;
	text-decoration: none;
	transition: all .2s ease;
	outline: none;

	&:hover {
		color: $teal;
	}
}

abbr {
	text-decoration: none;
}

.container {
	width: 100%;
	max-width: 1140px;
	margin: 0 auto;
	padding: 0 1rem;

	&__full {
		max-width: none;
	}
}

img {
	max-width: 100%;
	height: auto;
}

.screen-reader-text {
	display: none;
}
