.mb-nutrition-facts {
	margin: 0;
	display: grid;
	grid-template-columns: 50% 50%;

	dt {
		font-weight: 600;
		line-height: 1.2;

		.fonts-loaded & {
			font-family: $proxima-600;
			font-weight: 400;
		}

		&.mb-nutrition-facts-indent {
			padding-left: 20px;
			font-family: $proxima-400;
			font-weight: 400;
		}
	}

	dd {
		margin: 0;
		text-align: right;
		line-height: 1.2;
	}

	&-hr {
		grid-column: 1 / span 2;
		border-top: 5px solid $text;
		display: block;
		margin: 10px 0;
		padding-top: 5px;
		color: $black-0;
	}
} // .mb-nutrition-facts

#nutrition-label .nf {
	width: 100% !important;
}
