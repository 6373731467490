.block-slider-cards {
	.slider {
		display: none;
	}

	.slick-slider {
		margin-top: 3rem;
		padding-left: 10px;
		padding-right: 0;
	}

	a.card, div.card {
		margin-right: 25px !important;
		margin-bottom: 3px;
		width: auto;
		box-shadow: none;

		@include media($tablet-wide) {
			box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -3px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
		}

		&.slick-active {
			box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -3px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
		}
	} // a.card

	.slick-list {
		padding-left: 15px;
	}
} // .block-slider-cards


.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-touch-callout: none;
	user-select: none;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}

.slick-list {
	position: relative;
	overflow: hidden;
	display: block;
	margin: 0;
	padding: 0;

	&:focus {
		outline: none;
	}

	&.dragging {
		cursor: pointer;
		cursor: hand;
	}
}

.slick-slider .slick-track,
.slick-slider .slick-list {
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	left: 0;
	top: 0;
	display: block;

	&:before,
	&:after {
		content: '';
		display: table;
	}

	&:after {
		clear: both;
	}

	.slick-loading & {
		visibility: hidden;
	}
}

.slick-slide {
	float: left;
	height: 100%;
	min-height: 1px;
	display: none;

	[dir='rtl'] & {
		float: right;
	}

	img {
		display: block;
	}

	&.slick-loading img {
		display: none;
	}

	&.dragging img {
		pointer-events: none;
	}

	.slick-initialized & {
		display: block;
	}

	.slick-loading & {
		visibility: hidden;
	}

	.slick-vertical & {
		display: block;
		height: auto;
		border: 1px solid transparent;
	}
} // .slick-slide

.slick-arrow.slick-hidden {
	display: none;
}

.slick-prev, .slick-next {
	font-size: 2.5rem;
	position: absolute;
	top: 50%;
	margin-top: -2.5rem;
	color: $grey-2;

	&:hover {
		color: $teal;
	}
}

.slick-prev {
	left: -5px;

	@include media($tablet) {
		left: -15px;
	}
}

.slick-next {
	right: -5px;

	@include media($tablet) {
		right: -15px;
	}
}
