/**
 * Styles for the single column, full width, thin content block
 */

.block-full-col.thin {
	.btn, .button {
		padding: .6rem 2rem;
		font-size: 1.2rem;
	}

	.gform_wrapper {
		margin-top: 30px;
	}

	.gform_confirmation_wrapper {
		padding: 4rem 0;
		font-weight: 700;
		font-size: 1.6rem;
	}

	.gfield {
		float: left;
		width: 48%;
		font-size: 1rem;

		&:nth-child(even) {
			float: right;
		}
	}

	.success-path-form .gfield {
		float: none;
		width: 100%;
	}

	> :first-child {
		margin-top: 0;
	}

	> :last-child {
		margin-bottom: 0;
	}

	h1 {
		margin: 2rem 0;
	}

	h2 {
		font-size: 1.8rem;
		margin-top: 2rem;
		margin-bottom: 1.4rem;

		&:first-child {
			margin-top: 0;
		}
	}

	p, ul, ol {
		font-size: 1.4rem;
		line-height: 1.4;
		margin: 1.4rem 0;
	}
} // .thin
