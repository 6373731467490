.mb-menu-resources {
	$mb-menu-resource-height: 30px;

	@include media($tablet) {
		@include clearfix;
		border-top: 1px solid $border-color;
		border-bottom: 1px solid $border-color;
		padding: 20px 0;

		&-wrapper {
			@include clearfix;
			margin: 0;
		}

		.mb-fixed-header {
			display: block;
			margin-bottom: 1rem;
		}
	}

	&-item {
		@extend .clearfix;
		margin-bottom: 20px;

		@include media($tablet) {
			float: left;
			margin-right: 3%;
			margin-bottom: 0;

			&:last-of-type {
				margin-right: 0;
			}

			&__servings {
				width: 15%;
			}

			&__measurement {
				width: 19%;
			}

			&__resources {
				width: 57%;
				padding-right: 120px;
				margin-right: -120px;

				.mb-menu-resources-item-label {
					text-align: left;
				}
			}

			&__download {
				width: 120px;
				margin-top: $default-font-size + 10;
				margin-left: 3%;
			}
		}

		&:last-of-type {
			margin-bottom: 0;
		}

		&-label {
			@extend .small;
			color: $black-0;
			display: block;
			margin-bottom: 10px;

			@include media($tablet) {
				text-align: center;
			}
		}
	}

	&-number {
		text-align: center;
		font-size: $mb-menu-resource-height;
		line-height: $mb-menu-resource-height;
		font-weight: 900;

		.fonts-loaded & {
			font-family: $proxima-900;
			font-weight: 400;
		}

		&-less, &-more {
			@include size($mb-menu-resource-height);
			position: relative;
			display: block;
			background-color: $teal;
			text-indent: -99999px;
			cursor: pointer;

			&:hover {
				background-color: $orange;
			}

			&:before, &:after {
				content: '';
				display: block;
				position: absolute;
				background-color: #FFF;
				border-radius: 2px;
			}

			&:before {
				width: 16px;
				height: 4px;
				left: ($mb-menu-resource-height - 16px) / 2;
				top: ($mb-menu-resource-height - 4px) / 2;
			}
		}

		&-less {
			float: left;
		}

		&-more {
			float: right;

			&:after {
				width: 4px;
				height: 16px;
				left: ($mb-menu-resource-height - 4px) / 2;
				top: ($mb-menu-resource-height - 16px) / 2;
			}
		}
	}

	&-radio {
		&-item {
			display: block;
			float: left;
			width: 50%;
			border-radius: 3px;
			border: 1px solid $teal;
			color: $teal !important;
			text-align: center;
			text-decoration: none !important;
			text-transform: uppercase;
			line-height: 1;
			padding: 3px 0;
			font-weight: 600;

			@include media($tablet) {
				border: 0;
				background: none;
				color: $text !important;
				padding: 0;
				text-align: left;
				position: relative;
				height: $mb-menu-resource-height;
				line-height: $mb-menu-resource-height;
				padding-left: 20px + 8px;

				&:before, &:after {
					content: '';
					display: block;
					position: absolute;
					top: 5px;
					left: 0;
					border-radius: 50px;
					transition: all .2s ease;
				}

				&:before {
					width: 20px;
					height: 20px;
					border: 1px solid $text;
					background: #FFF;
				}

				&:after {
					width: 20px - 6;
					height: 20px - 6;
					margin-top: 3px;
					margin-left: 3px;
				}
			}

			.fonts-loaded & {
				font-family: $proxima-600;
				font-weight: 400;
			}

			&:first-of-type {
				border-radius: 3px 0 0 3px;
			}

			&:last-of-type {
				border-radius: 0 3px 3px 0;
			}

			&.active {
				background-color: $teal;
				color: #FFF !important;

				@include media($tablet) {
					background: none;
					color: $text !important;

					&:before {
						border-color: $teal;
					}

					&:after {
						background-color: $teal;
					}
				}

				&:hover {
					&:after {
						background-color: $orange;
					}
				}
			}

			&:hover, &:active {
				background-color: $orange;
				color: #FFF !important;
				border-color: $teal;

				@include media($tablet) {
					background: none;
					color: $text !important;

					&:before {
						border-color: $orange !important;
					}
				}
			}
		}
	}

	&-download.btn {
		width: 100%;
		background-color: $teal;
		border-radius: 100px;
		box-shadow: none;
		border: 0;

		&:hover {
			background-color: $orange;
		}
	}

	&-select {
		&:hover .selectize-input, &:hover .selectize-dropdown {
			border-color: $orange;
		}

		.selectize-input {
			border-radius: 16px;
			border: 1px solid $black-0;
			box-shadow: none;
			color: $text;
			padding: 0 1rem;
			height: $mb-menu-resource-height + 2;
			line-height: $mb-menu-resource-height;
			transition: border .2s ease;

			&.dropdown-active {
				border-radius: 16px 16px 0 0;
			}
		}

		.selectize-dropdown {
			border-radius: 0 0 16px 16px;
			border: 1px solid $black-0;
			transition: border .2s ease;

			&-content {
				border-radius: 0 0 16px 16px;
				border: 0;
				padding: 5px 0;
			}

			.active {
				background-color: $orange;
				color: #FFF;
			}
		} // .selectize-dropdown

		.item[data-value]:before, .option[data-value]:before {
			font-family: 'oamm' !important;
			font-style: normal !important;
			font-weight: normal !important;
			font-variant: normal !important;
			text-transform: none !important;
			speak: none;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			margin-top: 5px;
			float: left;
			font-size: 1.2rem;
			margin-right: 7px;
		}

		.option[data-value]:before {
			margin-top: 0;
		}

		.item[data-value="all_resources"]:before, .option[data-value="all_resources"]:before {
			content: '\4f';
		}

		.item[data-value="shopping_list"]:before, .option[data-value="shopping_list"]:before {
			content: '\65';
		}

		.item[data-value="prep_instructions"]:before, .option[data-value="prep_instructions"]:before {
			content: '\61';
		}

		.item[data-value="cooking_day_instructions"]:before, .option[data-value="cooking_day_instructions"]:before {
			content: '\62';
		}

		.item[data-value="recipe_cards"]:before, .option[data-value="recipe_cards"]:before {
			content: '\63';
		}

		.item[data-value="labels"]:before, .option[data-value="labels"]:before {
			content: '\66';
		}

		.item[data-value="thaw_sheet"]:before, .option[data-value="thaw_sheet"]:before {
			content: '\64';
		}
	}
} // .mb-menu-resources
