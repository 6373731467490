.alert {
	margin-bottom: 1.5rem;
	border-radius: 3px;

	.portal-alert & {
		margin-bottom: 0;
		text-align: center;
		position: relative;
		z-index: 1;
	}

	.portal-form & {
		margin-top: 25px;
		text-align: center;
	}

	&-success {
		padding: .5rem 1rem;
		color: #FFF;
		background-color: $success;
	}

	&-error {
		padding: .5rem 1rem;
		color: #FFF;
		background-color: $error;

		&__light {
			color: $text;
			border: 1px solid $error;
			background-color: #FFEBEE;
			width: 50%;
			margin: 1rem auto !important;
			min-width: 290px;
		}
	}

	&-warning {
		padding: .6rem 1rem;
		background-color: #FFFDE7;
		border: 1px solid #FFF176;
	}

	&-signup {
		margin-top: 1.5rem;
		padding: 1rem;
		background-color: transparentize($teal, .9);
		border: 1px solid $teal;
		text-align: center;
		font-size: 16px;

		@include media($mobile-wide) {
			font-size: 18px;
		}

		@include media($tablet) {
			font-size: 20px;
			padding: 1.5rem;
		}

		p {
			margin: .5rem 0;

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&-success, &-error {
		a {
			color: #FFF;
			text-decoration: underline;
		}
	}

	&-business {
		text-align: center;
		margin-bottom: 0;
	}

	i.fa, i.fas, i.icon {
		float: left;
		margin-right: 7px;
		line-height: 1.35;
	}

	ul {
		@extend .list-reset;
	}
} // .alert
