.mb-search-form {
	display: flex;
	border-radius: 3px;
	background-color: #FFF;
	box-shadow: 0 1px 3px 0 rgba(#000, .075), 0 3px 1px -2px rgba(#000, .075), 0 1px 4px 0 rgba(#000, .075);

	&.focus {
		box-shadow: 0 0 0 1px rgba($teal, .75), 0 3px 1px -2px rgba($teal, .5), 0 0 8px 0 rgba($teal, .75);
	}

	&-input {
		flex: 10;
		font-size: 1.2rem;
		padding: 1rem !important;
		border: 0 !important;
		border-radius: 3px;
		box-shadow: none !important;
		background-color: transparent;

		@include media($tablet) {
			font-size: 1.4rem;
		}
	}

	&-clear {
		flex: 1;
		align-self: center;
		text-align: center;
		color: $text !important;

		@include media($tablet) {
			display: none;
		}
	}

	&-submit {
		flex: 1;
		min-width: 50px;
		max-width: 60px;
		margin: .5rem;
		background-color: $orange;
		color: #FFF;
		font-size: 1.5rem;
		text-align: center;
		border: 0;
		border-radius: 3px;
		transition: all .2s ease;

		&:hover, &:focus {
			background-color: $teal;
		}

		&.disabled, &[disabled] {
			background-color: $grey-2;
			cursor: not-allowed;
		}

		i.fas, i.fa {
			margin-top: 3px;
		}
	}
} // .mb-search-form

.mb-search-count {
	@extend .small;
	font-size: 1rem;
	font-family: $proxima-400;
	line-height: 1;
	margin: 1rem 0;

	@include media($tablet) {
		padding: 0;
	}

	a {
		text-decoration: none;
	}

	&-members {
		display: none;

		@include media($desktop) {
			display: inline-block;
			margin-left: 1rem;
		}
	}

	&-sort {
		float: right;
		font-family: $proxima-600;
		text-transform: uppercase;

		a {
			color: $text;

			&:hover {
				color: $text;
			}
		}
	}
} // .mb-search-count

.mb-search-clear {
	display: none;

	@include media($tablet) {
		display: inline-block;
		margin-left: 1rem;
	}
} // .mb-search-clear

.mb-search-help {
	font-size: 1.4rem;
	text-align: center;
	padding: 2rem;
} // .mb-search-help

.mb-search-favs-create {
	position: relative;
	margin-bottom: 2rem;

	@include media($tablet-wide) {
		margin-bottom: 0;
	}

	.submenu {
		top: 100%;
		right: 0;
		width: 100%;
		max-width: 300px;
		margin-top: 10px;

		@include media($tablet-wide) {
			margin-top: 3rem;
		}
	}
} // .mb-search-favs-create
