.oamm-countdown {
	clear: both;
	text-align: center;
	display: flex;
	justify-content: center;
	margin: 2rem 0;

	&-item {
		padding: 1rem;
		border: 1px solid $teal;
		background: #FFF;
		border-radius: 5px;
		margin: 0 5px;
		width: 7rem;
	}

	&-item-count {
		font-family: $proxima-900;
		font-size: 3rem;
	}

	small {
		display: block;
		font-size: 1rem;
	}
} //.oamm-countdown
