@mixin appearance($value) {
	@include prefixer(appearance, $value, webkit moz ms o spec);
}

@mixin spacing($margin, $padding: $margin) {
	margin: $margin;
	padding: $padding;
}

@mixin placeholder($color) {
	&::-webkit-input-placeholder { font-style: italic; color: $color; }
	&:-ms-input-placeholder { font-style: italic; color: $color; }
}

@mixin gradient($top, $bottom) {
	background-color: $top;
	background: linear-gradient(to bottom, $top, $bottom);
}

@mixin card-shadow($level: .05) {
	box-shadow: 0 1px 3px 0 rgba(#000, $level), 0 3px 1px -2px rgba(#000, $level), 0 1px 4px 0 rgba(#000, $level);
}

@mixin headline-bars($size: h1) {
	line-height: 1.25;
	letter-spacing: -.65px;
	text-transform: uppercase;
	position: relative;

	.fonts-loaded & {
		letter-spacing: .02em;
	}

	@if $size == hero {
		color: $white;
		font-size: 2.4rem;
		font-family: $helvetica;
		font-weight: 600;
		font-display: swap;
		padding: 20px 0;

		.fonts-loaded & {
			font-family: $proxima-600;
			font-weight: 400;
		}

		@include media($tablet) {
			font-size: 50px;
			padding: 25px 0;
		}

		@include media($tablet-wide) {
			font-size: 76px;
			padding: 30px 0;
		}
	} @else {
		padding: 1rem 0;
	}

	@if $size == h1 {
		font-size: 30px;

		@include media($tablet-wide) {
			font-size: 36px;
		}
	} @else if $size == h2 {
		font-size: 20px;

		@include media($tablet-wide) {
			font-size: 26px;
		}
	}

	@include media($tablet) {
		margin-top: 0;
	}

	&:before, &:after {
		content: '';
		display: block;
		background-color: $orange;
		position: absolute;
		left: 0;

		@if $size == hero {
			width: 100px;
			height: 8px;
			box-shadow: 0 1px 20px rgba(0, 0, 0, .2), 0 1px 1px rgba(0, 0, 0, .3);

			@include media($tablet) {
				width: 120px;
				height: 10px;
			}
		} @else {
			width: 90px;
			height: 7px;
		}
	}

	&:before {
		top: 0;
	}

	&:after {
		bottom: 0;
	}

	&.headline-center {
		&:before, &:after {
			left: 50%;

			@if $size == hero {
				margin-left: -50px;

				@include media($tablet) {
					margin-left: -60px;
				}
			} @else {
				margin-left: -45px;
			}
		}
	} // &.headline-center

	&.headline-right {
		&:before, &:after {
			left: auto;
			right: 0;
		}
	} // &.headline-center
} // headline-bars()

@mixin cover() {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
	width: 100%;
	height: 100%;
}

@mixin gradient-overlay($params: dark-left) {
	position: relative;
	z-index: 1;

	&:before {
		@include cover;

		@if $params == white-left {
			background-color: rgba(255, 255, 255, .4);
			background: linear-gradient(65deg, rgba(255, 255, 255, .6) 0%, rgba(255, 255, 255, 0) 80%);
		}

		@if $params == white-right {
			background-color: rgba(255, 255, 255, .4);
			background: linear-gradient(-10deg, rgba(255, 255, 255, .6) 0%, rgba(255, 255, 255, 0) 80%);
		}

		@if $params == white-cover {
			background-color: rgba(255, 255, 255, .6);
		}

		@if $params == dark-left {
			background-color: rgba(0, 0, 0, .35);
			background: linear-gradient(65deg, rgba(0, 0, 0, .55) 0%, rgba(0, 0, 0, 0) 80%);
		}

		@if $params == dark-right {
			background-color: rgba(0, 0, 0, .35);
			background: linear-gradient(-10deg, rgba(0, 0, 0, .55) 0%, rgba(0, 0, 0, 0) 80%);
		}

		@if $params == dark-cover {
			background-color: rgba(0, 0, 0, .6);
		}

		@if $params == orange-cover {
			background-color: rgba($orange, .92);
		}

		@if $params == teal-cover {
			background-color: rgba($teal, .92);
		}

		content: '';
		z-index: -1;
	}
} // gradient-overlay()
