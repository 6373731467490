.testimonial {
	@extend .clearfix;
	background-color: $grey-0;
	border-top: 1px solid $grey;
	border-bottom: 1px solid $grey;
	width: 100%;
	padding: 1.5em 0;

	&.testimonial-teal {
		color: $white;
		border-color: $teal-2;
		background-color: $teal;
		text-shadow: 0 1px 1px rgba(0, 0, 0, .3);

		.testimonial-image img {
			border-color: $grey;
		}
	}

	&.testimonial-black {
		color: $white;
		border-color: $black-2;
		background-color: $black-2;
		text-shadow: 0 1px 1px rgba(0, 0, 0, .3);

		.testimonial-image img {
			border-color: $grey;
		}
	}

	.container {
		max-width: 670px;
		display: table;
	}

	.testimonial-image {
		display: table-cell;
		padding-right: 2em;
		width: 25%;
		vertical-align: middle;

		@include media($mobile-landscape) {
			width: 16%;
		}

		@include media($tablet) {
			width: 14%;
		}

		img {
			border-radius: 50%;
			border: 2px solid $black;
			width: 100%;
			height: auto;
			box-shadow: 0 1px 3px rgba(0, 0, 0, .35);
			vertical-align: middle;
		}
	}

	.testimonial-content {
		display: table-cell;
		text-align: left;
		line-height: 1.35;
		width: 75%;
		font-family: $serif;
		font-style: italic;
		vertical-align: middle;

		@include media($tablet) {
			width: 80%;
		}
	} // .testimonial-content

	.testimonial-author {
		display: inline-block;
		text-transform: uppercase;
		margin-left: 6px;
	}
} // .testimonial
