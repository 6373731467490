.affiliate-form_wrapper,
.gform_confirmation_wrapper {
	@extend .clearfix;
	margin-bottom: 2rem;

	@include media($tablet) {
		float: right;
		width: 50%;

		.second-col & {
			float: none;
			width: 100%;
		}
	}
}

.affwp-error {
	@extend .clearfix;
}

#affwp-login-form {
	color: #FFF;
	background: $teal;
	padding: 2rem 2rem 0;
	border-radius: 5px;

	@include media($tablet) {
		float: left;
		width: 43%;
	}

	fieldset {
		margin: 0;
		padding: 0;
		border: 0;

		legend {
			margin-bottom: 2rem;
			font-size: 1.5rem;
			font-weight: 400;
			font-family: $proxima-600;
		}
	}

	p {
		margin: 0 0 2rem;
	}

	a {
		color: #FFF;
		text-decoration: underline;

		&:hover {
			color: $text;
		}
	}

	label {
		display: inline-block;
		font-family: $proxima-600;
		margin-bottom: 10px;
		text-transform: uppercase;
	}

	input[type='text'], input[type='url'], input[type='email'], input[type='password'], textarea {
		width: 100%;
		color: $text;
	}

	.affwp-lost-password {
		line-height: 2;
	}
} // #affwp-login-form


#affwp-affiliate-dashboard {
	#affwp-affiliate-dashboard-tabs {
		@extend .clearfix;
		border-bottom: 1px solid $grey !important;
		white-space: nowrap;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar;

		li {
			display: inline-block;
			vertical-align: middle;
			padding: 0;

			&:last-child a {
				margin-right: 0;
			}

			a {
				display: inline-block;
				padding: .75rem 0;
				margin-right: 1.75rem;

				&:hover {
					//border-bottom: 1px solid $teal;
				}
			}

			&.active {
				a {
					color: $text;
				}
			}
		}
	} // #affwp-affiliate-dashboard-tabs

	h4 {
		font-size: 1.5rem;
	}
} // #affwp-affiliate-dashboard

table.affwp-table th {
	background-color: $grey-1;
	font-weight: 400;
	font-family: $proxima-600;
}

table.affwp-table th, table.affwp-table td {
	border-color: $grey-2;
}

#affwp-affiliate-dashboard-campaign-stats table th:nth-child(4),
#affwp-affiliate-dashboard-campaign-stats table th:nth-child(5),
#affwp-affiliate-dashboard-campaign-stats table td:nth-child(4),
#affwp-affiliate-dashboard-campaign-stats table td:nth-child(5) {
	display: none;
}

#affwp-graphs-filter {
	@extend .clearfix;

	.selectize-control {
		width: 220px;
		float: left;
		margin-right: .5rem;
	}

	.button {
		height: 40px;
		line-height: 40px;
		padding-top: 0;
		padding-bottom: 0;
		float: left;
	}
} // #affwp-graphs-filter
