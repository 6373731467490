$menus-list-item-width:   180px;
$menus-list-item-height:  130px;
$menus-list-item-padding: 1rem;

.mb-menus-list {
	@extend .clearfix;
	display: none;
	position: fixed;
	z-index: 999999;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 4rem 0 6rem 5%;
	background-color: rgba(#FFF, .97);
	overflow-y: scroll;

	@include media($tablet) {
		display: block !important;
		position: relative;
		z-index: 1;
		border-top: 1px solid $border-color;
		border-bottom: 1px solid $border-color;
		padding: 1rem 0 5px;
		margin-bottom: 1rem;
		background: none;
		overflow-y: inherit;

		.slick-list {
			height: $menus-list-item-height + $default-font-size + 13;
			padding-top: 8px !important;
			padding-bottom: 5px !important;
		}
	}

	&__recipe {
		ul {
			padding: 0 2rem;

			@include media(new-breakpoint(min-width 930px 8)) {
				padding: 0 1rem;
			}
		}

		&.loading {
			background: url('../images/loading.gif') no-repeat 50% 45%;
			min-height: 180px;
			pointer-events: none;
		}
	} // .mb-menus-list__recipe

	.slick-arrow {
		margin-top: -1.5rem;
	}

	.slick-prev {
		left: 0;

		@include media(new-breakpoint(min-width 930px 8)) {
			left: -15px;
		}
	}

	.slick-next {
		right: 0;

		@include media(new-breakpoint(min-width 930px 8)) {
			right: -15px;
		}
	}

	&__custom-menus {
		@include spacing(0);
		display: block;
		position: relative;
		z-index: 1;
		top: auto;
		right: auto;
		bottom: auto;
		left: auto;
		background: none;
		border: 0;

		ul {
			@include clearfix;
			top: auto !important;
			transform: none !important;
			margin-bottom: 2rem;
		}

		.mb-menus-list-item {
			width: 100%;
			margin-right: 0;
			margin-bottom: 1rem;
			height: auto;

			&:hover {
				transform: translateY(-2px);

				.mb-menus-list-item-name {
					color: $teal;
				}

				.mb-menus-list-item-status {
					transform: translateY(-1.2rem);
				}

				.mb-menus-list-item-link {
					transform: translateY(.2rem);
				}
			}

			&.empty-menu-slot {
				height: 90px;
				line-height: 90px;
			}

			&-name {
				font-size: 1.2rem;
				min-height: auto;
				margin-bottom: 10px;

				@include media($tablet) {
					font-size: 1.4rem;
					margin-bottom: 1rem;
				}
			}

			&-delete {
				position: relative;
				z-index: 2;
				float: right;
				font-size: 1.2rem;
				color: $grey-2;

				@include media($tablet) {
					font-size: 1.4rem;
				}

				&:hover {
					color: $error;
				}
			}

			.mb-numbers {
				display: block;
				position: relative;
				z-index: 1;
				margin: 0;
				text-align: left;
				max-width: none;

				&-item-number {
					font-size: 1.2rem;

					@include media($tablet) {
						font-size: 1.4rem;
					}
				}

				&-item-number, &-item-label {
					line-height: 1.2rem;

					@include media($tablet) {
						line-height: 1.4rem;
					}
				}
			}
		} // .mb-menus-list-item
	} // .mb-menus-list__custom-menus

	&-close {
		position: absolute;
		top: 1rem;
		right: 1rem;
		font-size: 1.6rem;
		color: $text;

		@include media($tablet) {
			display: none;
		}
	}

	&-title {
		@extend .small;
		display: none;

		@include media($tablet) {
			color: $black-0;
			position: absolute;
			top: -7px;
			background: $grey-0;
			display: inline-block;
			margin: 0;
			padding-right: 10px;
		}
	}

	ul {
		@extend .list-reset;

		@include media($tablet) {
			position: relative;
		}

		&.loading {
			background: url('../images/loading.svg') no-repeat 50% 25px;
			min-height: 100px;
			pointer-events: none;
		}
	}

	&-item {
		@include card-shadow(.10);
		background: #FFF url('../images/bg-custom-menu.png') no-repeat 50% 50%;
		background-size: cover;
		position: relative;
		cursor: pointer;
		transition: all .2s ease;
		border-radius: 5px;
		margin-bottom: 10px;
		overflow: hidden;
		height: 9rem;
		float: left;
		width: 45%;
		margin-right: 5%;

		@include media($mobile-wide) {
			height: 8rem;
		}

		@include media($tablet) {
			width: $menus-list-item-width;
			height: $menus-list-item-height;
			margin-right: $menus-list-item-padding;
		}

		&__found {
			@include card-shadow(.025);

			.mb-menus-list-item-wrapper {
				&:after {
					background-color: rgba(#FFF, .93);

					@include media($tablet) {
						background-color: rgba(#FFF, .97);
					}
				}

				.mb-menus-list-item-name {
					color: $black-0;
				}

				.mb-menus-list-item-status {
					color: $teal;
				}
			}
		}

		&:last-of-type {
			margin-right: 0;
		}

		&:hover {
			transform: scale(1.05);

			@include media($tablet) {
				transform: scale(1.1);
			}

			.mb-menus-list-item-status {
				transform: translateY(-1.2rem);
			}

			.mb-menus-list-item-link {
				transform: translateY(.2rem);
			}
		}

		&:focus {
			outline: none;
		}

		&.loading {
			.mb-menus-list-item-name, .mb-menus-list-item-status {
				filter: blur(2px);
			}

			.mb-menus-list-item-wrapper:after {
				background: rgba(#FFF, .97) url('../images/loading.gif') no-repeat 50% 50%;
			}
		}

		&.empty-menu-slot {
			background: transparent;
			border: 1px dashed $teal;
			text-align: center;
			box-shadow: none;
			padding: 0;
			height: 9rem;
			line-height: 9rem;

			@include media($tablet) {
				height: $menus-list-item-height;
				line-height: $menus-list-item-height;
			}

			&:after {
				display: none;
			}

			&:hover {
				transform: none;
				background-color: #FFF;
				border-color: $orange;

				a {
					color: $orange;
				}
			}

			a {
				color: $teal;
				font-size: .8rem;
				text-decoration: none;
				text-transform: uppercase;
			}
		} // &,empty-menu-slot

		&-wrapper {
			position: relative;
			z-index: 1;
			height: 100%;
			padding: 1rem;

			&:after {
				@extend .cover;
				content: '';
				z-index: 0;
				background-color: rgba(#FFF, .9);

				@include media($tablet) {
					background-color: rgba(#FFF, .95);
				}
			}
		} // &-wrapper

		&-name {
			position: relative;
			z-index: 1;
			font-weight: 700;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			line-height: 1.4em;
			min-height: 1.4em * 2;
			max-height: 1.4em * 2;
			transition: all .2s ease;

			@include media($tablet) {
				-webkit-line-clamp: 3;
				max-height: 1.4em * 3;
			}

			.fonts-loaded & {
				font-family: $proxima-700;
				font-weight: 400;
			}
		}

		&-status {
			position: absolute;
			z-index: 1;
			bottom: 1rem;
			left: 1rem;
			display: inline-block;
			font-size: .8rem;
			text-transform: uppercase;
			transition: all .2s ease;
			color: $orange;

			i {
				margin-right: 3px;
			}

			.icon-close {
				margin-top: -2px;
			}
		}

		&-link {
			transform: translateY(2rem);
			position: absolute;
			z-index: 1;
			bottom: 1.2rem;
			left: 0;
			right: 0;
			display: block;
			margin: 0 -1rem -1rem;
			text-align: center;
			text-decoration: none !important;
			text-transform: uppercase;
			color: #FFF !important;
			font-size: .8rem;
			font-weight: 600;
			letter-spacing: .05em;
			border-radius: 0 0 3px 3px;
			line-height: 1.4rem;
			background-color: $orange;

			&:hover {
				background-color: $teal;
				color: #FFF;
			}

			.fonts-loaded & {
				font-family: $proxima-600;
				font-weight: 400;
			}

			.icon {
				margin-top: -6px;
			}
		}
	} // mb-menus-list-item
} // mb-menus-list
