.shopping-list {
	margin-bottom: 2rem;

	@include media($tablet) {
		-ms-column-count: 2;
		column-count: 2;
		-ms-column-gap: 2rem;
		column-gap: 2rem;
	}

	&:last-of-type {
		margin-bottom: 0;
	}
} // .shopping-list

.shopping-list-group {
	margin-bottom: 2rem;
	-webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
	          page-break-inside: avoid; /* Firefox */
	               break-inside: avoid; /* IE 10+ */

	h3 {
		margin-top: 0;
	}

	ul {
		@extend .list-reset;
	}
} // .shopping-list-group

.shopping-list-item {
	@extend .clearfix;
	line-height: 1.4rem;
	margin-bottom: 5px;

	label:hover {
		cursor: pointer;
	}

	input[type="checkbox"] {
		height: 1.4rem;
		width: 1.4rem;
		vertical-align: bottom;
		appearance: none;
		margin-right: .2rem;
		outline: none;
		border: 0;

		&:before {
			content: '\78';
			font-family: 'oamm';
			font-size: 1.4rem;
			color: #AAA;
		}

		&:hover {
			cursor: pointer;
		}

		&:focus ~ span, &:focus:before {
			color: $teal;
		}

		&:checked {
			&:before {
				content: '\79';
				color: $teal;
			}

			~ span, ~ span a {
				color: $teal;
				text-decoration: line-through;
			}
		}
	} // input[type="checkbox"]

	&-name {
		font-weight: 600;

		.fonts-loaded & {
			font-weight: 400;
			font-family: $proxima-600;
		}
	}

	&-amount {
		float: right;
		color: $black-0;
	}
} // .shopping-list-item
