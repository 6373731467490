.mb-fixed {
	position: relative;
	padding-left: 40px;

	@include media($tablet) {
		padding-left: 0;
	}

	&-header {
		display: inline-block;
		transform: rotate(270deg);
		transform-origin: 0 0;
		margin: 0;
		position: absolute;
		top: 0;
		left: 0;
		font-weight: 900;
		text-transform: uppercase;
		text-align: right;

		@include media($tablet) {
			display: none;
			position: relative;
			transform: none;
			font-weight: 700;
			text-transform: inherit;
			text-align: left;
		}

		.fonts-loaded & {
			font-family: $proxima-900;
			font-weight: 400;

			@include media($tablet) {
				font-family: $proxima-700;
				font-weight: 400;
			}
		}

		&.fixed {
			position: fixed;
			left: 1rem;
			top: 1rem;
		}

		&.fixed_bottom {
			position: absolute;
			top: 100%;
			left: 0;
			margin-top: 0 !important;

			@include media($tablet) {
				position: relative;
				top: auto;
				left: auto;
			}
		}
	} // .mb-fixed-header

	&-section {
		@include card-shadow(.15);
		padding: 20px;
		margin-right: -1rem;
		margin-bottom: 2rem;

		@include media($tablet) {
			box-shadow: none;
			margin-right: 0;
			margin-bottom: 3rem;
			padding: 0;
		}
	} // .mb-fixed-section
} // .mb-fixed
