.business-form {
	&-input[type='text'], &-input[type='email'] {
		display: inline-block;
		border: 0;
		box-shadow: none;
		border-bottom: 1px solid $orange;
		height: 39px;
		margin-right: -6px;
	}

	&-input-append {
		display: inline-block;
		border-bottom: 1px solid $orange;
		height: 39px;
		padding: 10px 1rem;
	}
} // .business-form
