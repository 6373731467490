.cards-wrapper {
	margin: 1rem 0 0;

	@include media($tablet) {
		margin: 2em 0 0;
	}

	&.loading {
		background: url('../images/loading.svg') no-repeat 50% 25px;
		min-height: 100px;
		pointer-events: none;
	}
} // .cards-wrapper

$card-hover-transition: 'color .2s ease 0s, background-color .2s ease 0s, box-shadow .2s ease 0s, transform .2s ease 0s';

a.card, div.card {
	background-color: $white;
	border-radius: 3px;
	box-shadow: 0 1px 3px 0 rgba(#000, .075), 0 3px 1px -2px rgba(#000, .075), 0 1px 4px 0 rgba(#000, .075);
	color: $black-0;
	text-decoration: none !important;
	display: block;
	float: left;
	margin-bottom: 2em;
	margin-right: 6%;
	opacity: 1;
	position: relative;
	transform: none;
	transition: #{$card-hover-transition};
	width: 47%;
	overflow: hidden;
	will-change: transform, color, box-shadow;

	@include media($tablet-wide) {
		margin-right: 3%;
		width: 31.333%;

		&:nth-of-type(3n) {
			margin-right: 0;
			transition: opacity .4s ease .1s, transform .4s ease .1s, #{$card-hover-transition} !important;
		}

		.full-col &, .slider & {
			margin-right: 3%;
			width: 22.75%;

			&:nth-of-type(3n) {
				margin-right: 3%;
			}

			&:nth-of-type(4n) {
				margin-right: 0;
				transition: opacity .2s ease .3s, transform .2s ease .3s, #{$card-hover-transition};
			}
		}
	}

	@include media($desktop) {
		.mb-search &, .menu-recipes & {
			margin-right: 3%;
			width: 22.75%;

			&:nth-of-type(3n) {
				margin-right: 3%;
			}

			&:nth-of-type(4n) {
				margin-right: 0;
				transition: opacity .2s ease .3s, transform .2s ease .3s, #{$card-hover-transition};
			}
		}
	}

	&:hover {
		color: $teal;
		box-shadow: 0 0 0 1px rgba($teal, .75), 0 3px 1px -2px rgba($teal, .5), 0 0 14px 0 rgba($teal, .75);
		transform: translateY(-2px);

		.card-title {
			color: $teal;
		}
	}

	&.active {
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -3px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 1px 1px 2px transparentize($orange, .2);

		&:hover {
			background-color: $orange;
		}

		&:before {
			@extend .small;
			content: 'Current Menu';
			position: absolute;
			top: 0;
			left: 0;
			padding: .5rem 1rem;
			background-color: $orange;
			color: #FFF;
			border-radius: 3px 3px 0 0;
			width: 100%;
		}

		.card-title {
			margin-top: 2rem;
		}
	}

	&.card-full {
		width: 100%;
		float: none;
	}

	.loading &, &.loading {
		opacity: 0;
		transform: translateY(-1.5em);
	}

	&:nth-of-type(1n) {
		transition: opacity .6s ease 0s, transform .6s ease 0s, #{$card-hover-transition};
	}

	&:nth-of-type(2n) {
		margin-right: 0;
		transition: opacity .4s ease .2s, transform .4s ease .2s, #{$card-hover-transition};

		@include media($tablet) {
			margin-right: 3%;
		}
	}

	.mb-content & * {
		text-decoration: none;
	}

	.card-meal-type, .card-new {
		background: linear-gradient(to bottom, rgba(0, 0, 0, .65), rgba(0, 0, 0, 0));
		border-radius: 3px 3px 0 0;
		color: $white;
		display: block;
		font-family: $proxima-600;
		font-size: .85rem;
		padding: .4rem 1rem 1.65rem;
		position: absolute;
		text-shadow: 0 0 3px $black;
		text-transform: uppercase;
		width: 100%;
		pointer-events: none;
		line-height: 20px;

		&.new {
			background: linear-gradient(to bottom, rgba(0, 0, 0, .65), rgba(0, 0, 0, 0));

			i {
				color: $orange;
				font-size: 20px;
				float: left;
				margin-right: 5px;
				margin-top: -2px;
			}
		}

		&:before {
			border-radius: 50%;
			box-shadow: 0 0 3px $black;
			content: '';
			display: block;
			float: left;
			width: 10px;
			height: 10px;
			margin-right: 4px;
			margin-top: 5px;
		}

		&.no-dot:before {
			display: none;
		}

		&.breakfast:before {
			background-color: #FFCD00;
		}

		&.lunch:before {
			background-color: #50B74F;
		}

		&.dinner:before {
			background-color: #CE3D29;
		}

		&.addon:before {
			background-color: #D490D4;
		}

		&.side_dish:before {
			background-color: $teal;
		}

		&.snack:before {
			background-color: #4079FF;
		}

		&.dessert:before {
			background-color: #7D3E11;
		}
	} // .card-meal-type, .card-new

	.card-locked {
		color: $white;
		font-size: .85rem;
		line-height: 20px;
		position: absolute;
		top: .4rem;
		right: 1rem;
	}

	.card-favorite {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;
		padding: .4rem 1rem 1rem;
		font-size: 20px;
		color: #FFF;
		text-shadow: 0 0 3px $black;
		transition: all .2s ease;

		&:hover {
			animation: pulse 1s infinite;
			color: #FFB6C1;
			cursor: pointer;
		}

		&.favorited {
			color: #FFB6C1;
		}
	}

	.card-image {
		border-radius: 2px 2px 0 0;
		height: auto;
		vertical-align: top;
		width: 100%;

		&-background {
			height: 100px;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-size: cover;
		}

		&-wrapper {
			height: 145px;
			overflow: hidden;
			position: relative;
			z-index: -1;

			.card-image {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	} // .card-image

	.card-content {
		padding: .75rem;
		color: $black-0;

		@include media($tablet) {
			padding: 1rem;
		}
	} // .card-content

	.card-title {
		color: $black;
		display: block;
		font-family: $proxima-700;
		font-size: $card-font-size;
		letter-spacing: .02em;
		line-height: $card-line-height;
		transition: all .2s ease 0s;

		&.no-image {
			margin-top: 2rem;
		}
	} // .card-title

	.card-modified {
		display: block;
		font-family: $serif;
		font-size: 13px;
		margin-top: 1rem;
	}

	.card-featured {
		display: block;
		font-family: $serif;
		font-size: 13px;
		margin-top: 1rem;
	}

	.card-description {
		margin-bottom: 0;
	}

	.card-more {
		display: block;
		margin-top: 1rem;
	}

	ul {
		font-size: 13px;
		line-height: 1.3;
		margin: 1em 0 0;
		padding-left: 20px;
		list-style: inherit;
	}

	li {
		margin-top: .45em;
		line-height: inherit;
	}
} // .card
