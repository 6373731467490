@import 'blocks/business';
@import 'blocks/hero';
@import 'blocks/testimonial';
@import 'blocks/video';
@import 'blocks/newsletter';
@import 'blocks/thin';

.block {
	@extend .clearfix;
	padding: 2rem 0;

	@include media($tablet) {
		padding: 3rem 0;
	}

	@include media($tablet-wide) {
		padding: 5rem 0;
	}

	&.block-teal {
		color: $white;
		background-color: $teal;
	}

	&.block-grey, &.block-gray {
		background-color: $grey-0;
		border-top: 1px solid $grey;
		border-bottom: 1px solid $grey;
	}

	&.block-black {
		color: $white;
		background-color: $black;
	}

	&.block-orange {
		color: $white;
		background-color: $orange;

		.headline:before, .headline:after {
			background-color: $teal;
		}

		.btn, .button {
			@extend .btn-teal;
		}
	}

	&.block-image {
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: cover;
	}

	&.block-gift-card {
		border-top: 1px solid $grey;
	}

	&.block-newsletter p {
		font-size: 16px;
		line-height: 1.65;
	}

	&.block__separator {
		background-size: cover;
		background-position: 50% 50%;
		background-repeat: no-repeat;
	}

	&.block__no-padding {
		padding: 0;
	}

	&:focus {
		outline: none;
	}

	h1 {
		margin-top: 1rem;
		margin-bottom: .85em;
	}

	li {
		line-height: 1.35;
	}

	p > .btn {
		margin-top: 1rem;
	}

	hr {
		border: 0;
		background-color: $orange;
		width: 160px;
		height: 10px;
		box-shadow: 0 1px 20px rgba(0, 0, 0, .15), 0 1px 1px rgba(0, 0, 0, .25);
		margin: 4rem auto;
		padding: 0;
	}

	.fourth-col {
		padding: 1rem 1.5rem 0;
	}

	.block-full-col {
		clear: both;
		margin-top: 1.75em;

		&.thin {
			max-width: 650px;
			margin: 0 auto;
		}
	}

	.screenshot {
		width: 100%;
		max-width: 300px;
		padding: 4px;
		box-shadow: 0 1px 4px rgba(0, 0, 0, .25) !important;
		border-radius: 3px;
		background-color: $white;
	}

	&.text-white {
		text-shadow: 0 0 15px rgba(0, 0, 0, .5);

		p, li {
			line-height: 1.5;
		}
	}

	&.border-gray {
		border-top: 1px solid $grey-1;
		border-bottom: 1px solid $grey-1;
	}

	&.border-black {
		border-top: 1px solid $black;
		border-bottom: 1px solid $black;
	}

	&.border-orange {
		border-top: 1px solid $orange;
		border-bottom: 1px solid $orange;
	}

	&.border-teal {
		border-top: 1px solid $teal;
		border-bottom: 1px solid $teal;
	}

	&.block__readable-font {
		p, ul, ol, li {
			font-size: 1.4rem;
			line-height: 1.4;
			margin: 1.4rem 0;
		}
	}
} // .block

.block-gradient {
	&-white_left {
		@include gradient-overlay(white-left);
	}

	&-white_right {
		@include gradient-overlay(white-right);
	}

	&-white_cover {
		@include gradient-overlay(white-cover);
	}

	&-dark_left {
		@include gradient-overlay(dark-left);
	}

	&-dark_right {
		@include gradient-overlay(dark-right);
	}

	&-dark_cover {
		@include gradient-overlay(dark-cover);
	}

	&-orange_cover {
		@include gradient-overlay(orange-cover);
	}

	&-teal_cover {
		@include gradient-overlay(teal-cover);

		.btn:hover, .button:hover {
			background-color: $orange-2;
		}
	}
} // .block-gradient

.bg-pattern {
	background-image: url('../images/footer-pattern.svg');
	background-position: 50% 50%;
	background-size: 200px;
	background-attachment: fixed;
}
