input[type='text'], input[type='search'], input[type='email'], input[type='tel'], input[type='password'], input[type='number'], input[type='url'], input[type='date'], select, textarea {
	@include placeholder($black-0);
	border: 1px solid $grey-1;
	box-shadow: 0 1px 2px rgba(0, 0, 0, .15);
	line-height: 1em;
	outline: none;
	padding: 10px 1rem;
	color: $black-2;

	&.input-full {
		width: 100%;
	}
}

select {
	padding: .5rem 1em;
}

.ember-select {
	margin-bottom: 1rem;
}

abbr[title] {
	text-decoration: none;
}

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
	box-shadow: 0 0 0 100px #FFF inset !important;
}

.input-row {
	@extend .clearfix;
	border-top: 1px solid $grey;
	padding: 1rem 0;

	.no-borders & {
		border: 0;
	}

	label {
		display: inline-block;
		font-family: $proxima-600;
		margin-bottom: 10px;
		text-transform: uppercase;
	}

	input[type='text'], input[type='search'], input[type='email'], input[type='tel'], input[type='password'], input[type='number'], input[type='url'], input[type='date'], select, textarea {
		width: 100%;
	}

	&:last-of-type {
		border-bottom: 1px solid $grey;
		margin-bottom: 1.75rem;
	}

	&-col {
		float: left;
		width: 50%;

		&.third {
			width: 31.333%;
			margin-right: 3%;

			&:last-of-type {
				margin-right: 0;
			}
		}
	}

	&-radio {
		margin-right: 1.5em;
	}
} // .input-row

.form-row {
	@extend .clearfix;
	border-top: 1px solid $grey;
	position: relative;

	&:last-of-type {
		border-bottom: 1px solid $grey;
		margin-bottom: 1em;
	}

	.form-label {
		@extend .small;
		background-color: $grey-0;
		bottom: 0;
		width: 135px;
		height: 100%;
		line-height: 44px;
		padding: 0 1em;
		position: absolute;

		.main-col & {
			width: 180px;
		}
	}

	.form-input {
		float: right;
		padding-left: 135px;
		width: 100%;

		.main-col & {
			padding-left: 180px;
		}
	}

	input[type='text'], input[type='email'], input[type='password'], input[type='number'], input[type='url'], input[type='date'] {
		border: 0;
		border-bottom: 1px solid #FFF;
		box-shadow: none;
		height: 44px;
		width: 100%;
		transition: border .2s ease;
		font-weight: 600;

		.fonts-loaded & {
			font-family: $proxima-600;
			font-weight: 400;
		}

		&.form-input {
			padding-left: 150px;

			.main-col & {
				padding-left: 195px;
			}
		}

		&:focus {
			border-bottom: 1px solid $orange;
		}
	}

	input[type='file'] {
		cursor: pointer;
		outline: none;
		padding: 1em;
		width: 50%;
	}

	.pull-right {
		float: right;
	}

	textarea {
		border: 0;
		height: 120px;
		line-height: 1.3;
		outline: none;
		padding: 1em;
		width: 100%;
		vertical-align: top;
		box-shadow: none;
		transition: border .2s ease;

		&.form-input {
			padding-left: 150px;

			.main-col & {
				padding-left: 195px;
			}
		}

		&:focus {
			border-bottom: 1px solid $orange;
		}
	}

	.live-preview-label, .form-instructions {
		display: inline-block;
		line-height: 1.75;
		padding: .5em 0 .5em 15px;
		color: $black-0;
		font-weight: 400;

		.fonts-loaded & {
			font-family: $proxima-400;
		}

		&.pull-right {
			line-height: 2em;
		}
	}

	> .form-instructions {
		padding-left: 150px;

		.gift-card & {
			text-transform: none;
		}
	}
} // .form-row

.help-inline {
	@extend .small;
	background-color: $error;
	border-radius: 3px;
	color: $white;
	display: inline-block;
	line-height: 1.75;
	margin: .5em 0 .5em 15px;
	padding: 0 .7em;
}

.picker {
	top: 100%;
	right: 0;
	left: 135px;
	width: auto !important;

	&__holder {
		outline: none;
		max-width: none;
	}
}

// GENERAL GRAVITYFORM STYLES
.gform_title {
	font-size: 1.5rem;
	margin: 2rem 0;
}

.gform_validation_container {
	display: none;
}

.gf_progressbar_title {
	margin-top: 0;
	margin-bottom: 1rem;
	font-size: 1.5rem;
}

.gf_progressbar {
	border-radius: 5px;
	background-color: $teal-0;
	margin-bottom: 2rem;

	.block-teal & {
		background-color: $grey-0;
	}

	&_percentage {
		@extend .small;
		border-radius: 5px;
		background-color: $teal-1;
		color: #FFF;
		padding: .4rem .8rem;
		text-align: right;

		.block-teal & {
			color: $text;
			background-color: $border-color;
		}
	}
} // .gf_progressbar

.gform_fields, .gfield_radio, .gfield_checkbox {
	@extend .list-reset;
}

.gfield {
	position: relative;
}

.gsection_description {
	margin-bottom: 2rem;
}

.gfield_label {
	display: inline-block;
	font-family: $proxima-600;
	margin-bottom: 10px;
	text-transform: uppercase;

	+ .gfield_description {
		margin-top: -5px;
	}
}

.ginput_container {
	@extend .clearfix;
	margin-bottom: 2rem;

	input[type='text'], input[type='url'], input[type='email'], input[type='tel'], input[type='password'], select, textarea {
		width: 100%;
		margin-bottom: 5px;
	}

	+ .gfield_description {
		margin-top: -1.5rem;
		margin-bottom: 2rem;
	}
} // .ginput_container

.ginput_complex {
	margin-bottom: 0;

	span {
		float: left;
		width: 50%;
		padding-right: .75rem;
		margin-bottom: 2rem;

		&:nth-child(2n) {
			padding-right: 0;
			padding-left: .75rem;
		}
	}
} // .ginput_complex

.gfield_radio, .gfield_checkbox, .affwp-form {
	input[type='radio'], input[type='checkbox'] {
		display: inline-block;
		margin-right: .5rem;
	}
}

.gfield_html {
	margin-bottom: 2rem;
}

.gfield_description {
	margin-top: -1rem;
	margin-bottom: 1rem;
}

.gfield_password_strength {
	position: absolute;
	top: 0;
	right: 0;
	height: 1rem;
	line-height: 1rem;
	padding-right: 2rem;

	&:after {
		display: block;
		content: '';
		position: absolute;
		right: 0;
		width: 1rem;
		height: 1rem;
		text-indent: -9999px;
		font-size: 0;
		background-color: $grey;
		top: 0;
		border-radius: 50%;
	}

	&.short:after, &.bad:after, &.mismatch:after {
		background-color: $error;
	}

	&.good:after {
		background-color: lighten($success, 15%);
	}

	&.strong:after {
		background-color: darken($success, 10%);
	}
} // .gfield_password_strength

.gform_previous_button {
	float: left;
}

.gform_next_button, .gform_wrapper input[type='submit'], .affwp-form input[type='submit'] {
	float: right;
}

.validation_error {
	margin-top: 2rem;
	color: $error;
}

.validation_message {
	margin-top: -2rem;
	margin-bottom: 2rem;
	color: $error;
}

.gform_confirmation_wrapper {
	text-align: center;
	font-size: 1.5rem;

	.block-full-col & {
		float: none;
		width: 100%;
	}
} // .gform_confirmation_wrapper
