.clearfix {
	@include clearfix;
}

.visuallyhidden {
	display: none !important;
}

.hide-mobile {
	display: none;

	@include media(new-breakpoint(min-width 480px 4)) {
		display: inline-block;
	}
}

.hide-print {
	@media print {
		display: none;
	}
}

@include media(new-breakpoint(min-width 480px 4)) {
	.show-mobile {
		display: none !important;
	}
}

@include media($tablet) {
	.show-tablet {
		display: none !important;
	}
}

.hide-tablet {
	display: none !important;

	@include media($tablet) {
		display: block !important;
	}
}

.hide-desktop {
	@include media($tablet) {
		display: none !important;
	}
}

.image-shadow {
	box-shadow: 10px 10px 0 $grey;
}

.lowercase {
	text-transform: lowercase;
}

.list-reset {
	@include spacing(0);
	list-style: none;
}

.cover {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
	width: 100%;
	height: 100%;
}
