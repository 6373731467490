.nav {
	font-weight: 700;
	text-transform: uppercase;

	.fonts-loaded & {
		font-family: $proxima-700;
		font-weight: 400;
		letter-spacing: .01em;
	}

	ul {
		@extend .list-reset;

		> li {
			display: inline-block;
			float: left;
		}
	}

	.nav-active > a,
	.nav-active > a:hover,
	.current-menu-item > a,
	.current-menu-item > a:hover,
	.current-cat > a,
	.current-cat > a:hover {
		color: $orange;
	}

	// BUTTON
	.nav-button a {
		padding: .5rem 20px;
		margin-right: 0;
		background-color: #FF7C00;
		color: #FFF;
		border-radius: 100px;
		line-height: 1rem;

		@include media($tablet-wide) {
			padding-left: 20px;
			padding-right: 20px;
		}

		&:hover {
			background-color: $teal;
			color: $white;
		}
	} // .nav-button

	.nav-avatar {
		$avatar-height: 20px;

		> a {
			> .fa, .fas, .far {
				margin-left: 4px;
			}

			> img {
				border-radius: 2px;
				height: $avatar-height;
				margin-right: 7px;
				width: $avatar-height;
				vertical-align: middle;
			}
		}
	} // .nav-avatar
} // .nav

.submenu, .nav-dropdown-submenu {
	@extend .list-reset;
	background-color: $white;
	border: 1px solid $grey-1;
	border-radius: 3px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, .2), 0 1px 15px rgba(0, 0, 0, .15);
	padding: 5px 0 !important;
	position: absolute;
	right: -10px;
	top: 50px;
	visibility: hidden;
	opacity: 0;
	text-align: left;
	font-weight: 600;

	.fonts-loaded & {
		font-family: $proxima-600;
		font-weight: 400;
	}

	&.loading {
		pointer-events: none;

		&:after {
			@extend .cover;
			content: '';
			background: rgba(#FFF, .7) url('../images/loading.svg') no-repeat 50% 50%;
			border: 0;
		}
	}

	&:before, &:after {
		content: '';
		display: block;
		height: 16px;
		position: absolute;
	}

	&:before {
		border-bottom: 8px solid $white;
		border-left: 9px solid transparent;
		border-right: 9px solid transparent;
		border-top: 8px solid transparent;
		right: 4px;
		top: -16px;
		width: 18px;
		z-index: 4;
	}

	&:after {
		border-bottom: 8px solid #E5E5E5;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-top: 8px solid transparent;
		right: 3px;
		top: -17px;
		width: 20px;
		z-index: 3;
	}

	> li {
		display: block !important;
		float: none !important;
		font-size: .8rem;

		&.active > a {
			color: $orange;
		}

		> a {
			color: $text;
			display: block !important;
			line-height: 1rem;
			padding: 10px 1rem;
			margin: 0;

			&:hover {
				color: $teal;
			}

			.fa, .fas, .far {
				margin-right: 3px;
				width: 13px;
				text-align: center;
			}
		}
	} // li

	&-description {
		background-color: $grey-0;
		font-family: $serif;
		font-style: italic;
		text-transform: none;
		line-height: 1.25;
		padding-top: 12px 16px 12px 15px;

		&:before {
			float: right;
			content: '\f05a';
			line-height: 1em;
			text-align: center;
			display: inline-block;
			color: $teal;
			font: normal normal normal 14px/1 FontAwesome;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}
	} // .description

	&-divider {
		height: 1px;
		border-top: 1px solid $grey;
		font-size: 0;
		margin: 5px 0;
	}

	&-form {
		@extend .clearfix;
		margin: 10px 1rem;
		border-radius: 3px;
		border: 1px solid $border-color;

		form {
			@extend .clearfix;
			font-size: 1rem;
		}

		&.focus, form.focus {
			box-shadow: 0 0 0 1px rgba($teal, .75), 0 3px 1px -2px rgba($teal, .5), 0 0 8px 0 rgba($teal, .75);
		}

		&-input {
			box-shadow: none !important;
			padding: 0 .65rem !important;
			float: left;
			width: 82%;
			height: 2.5rem;
			line-height: 2.5rem !important;
			border: 0 !important;
			margin-right: -.25rem;
		}

		&-btn {
			float: right;
			width: 18%;
			max-width: 55px;
			height: 2rem;
			line-height: 2rem;
			appearance: none;
			border: 0;
			background-color: $orange;
			color: #FFF;
			border-radius: 2px;
			margin-top: .25rem;
			margin-right: .25rem;
			transition: all .2s ease;

			&:hover {
				background-color: $teal;
			}
		}
	} // .submenu-form
} // .submenu

.dropdown-open + .submenu {
	opacity: 1;
	visibility: visible;
	z-index: 9999;
}

.nav__left {
	position: fixed;
	z-index: 999999;
	bottom: 0;
	left: 0;
	right: 0;
	box-shadow: 0 -1px 3px 0 rgba(#000, .1), 0 -3px 1px -2px rgba(#000, .1), 0 -1px 4px 0 rgba(#000, .1);
	background-color: #FFF;

	@include media(new-breakpoint(min-width 545px 4)) {
		display: block;
		position: absolute;
		bottom: auto;
		left: $logo-width + ($default-font-size * 2) - 10;
		right: auto;
		box-shadow: none;
		background: none;
	}

	@include media($desktop) {
		left: $logo-width + ($default-font-size * 2) + ($default-font-size);
	}

	li {
		width: 25%;

		@include media(new-breakpoint(min-width 545px 4)) {
			width: auto;
		}

		a:before {
			display: block;
			padding-bottom: 5px;
			font-size: 1.4rem;
			font-family: 'oamm' !important;
			font-style: normal !important;
			font-weight: normal !important;
			font-variant: normal !important;
			text-transform: none !important;
			speak: none;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			transition: font-size .5s ease, transform .5s ease, opacity .5s ease-in, opacity .1s ease-out;

			@include media(new-breakpoint(min-width 545px 4)) {
				display: none;
			}
		}

		&:nth-child(1) a:before {
			content: '\63'; // recipe-card
		}

		&:nth-child(2) a:before {
			content: '\70'; // heart
		}

		&:nth-child(3) a:before {
			content: '\6c'; // list-ordered
		}

		&:nth-child(4) a:before {
			content: '\6d'; // cutlery
		}
	}

	a {
		color: $text;
		display: block;
		text-align: center;
		font-size: .8rem;
		padding: 1rem 0;

		@include media(new-breakpoint(min-width 545px 4)) {
			display: inline-block;
			padding: 0 1rem;
			font-size: 1rem;
			line-height: $logo-height;
		}

		@include media(new-breakpoint(min-width 960px 12)) {
			padding: 0 10px;
		}

		@include media($desktop) {
			padding: 0 1rem;
		}

		&:hover {
			color: $teal;
		}
	}

	&.scroll-down a:before {
		opacity: 0;
		transform: translateY(100px);
		font-size: 0;
	}
} // .nav__left

.nav__center {
	display: none;
	text-align: center;

	@include media($tablet-wide) {
		display: block;
		position: absolute;
		left: $logo-width + $default-font-size;
		right: $logo-width + $default-font-size;
	}

	@include media($desktop) {
		left: $logo-width + ($header-padding * 2);
		right: $logo-width + ($header-padding * 2);
	}

	> ul > li {
		float: none;
	}

	a {
		color: $text;
		display: inline-block;
		padding: 0 10px;
		line-height: $logo-height;

		@include media($desktop) {
			padding: 0 1rem;
		}

		&:hover {
			color: $teal;
		}
	}
} // .nav__center

.nav__right {
	display: none;

	@include media(new-breakpoint(min-width 960px 12)) {
		display: block;
		position: absolute;
		right: 1rem;
	}

	@include media($desktop) {
		right: 2rem;
	}

	> ul > li {
		line-height: $logo-height;
	}

	a {
		color: $text;
		display: inline-block;
		margin-right: 20px;

		@include media($desktop) {
			margin-right: 2rem;
		}

		&:hover {
			color: $teal;
		}
	}

	.nav-button a {
		margin-left: -1rem;
	}
} // .nav__right

.nav-dropdown {
	position: relative;
	white-space: nowrap;

	> a {
		margin-right: 0;
	}

	&-open + &-submenu {
		opacity: 1;
		visibility: visible;
		z-index: 9999;
	}
} // .nav-dropdown

.nav-social {
	@extend .clearfix;
	margin-bottom: 3em;

	li {
		float: left;
		margin-right: 5px;
	}

	a {
		background-color: $black-0;
		border-radius: 2px;
		box-shadow: 0 1px 10px rgba(0, 0, 0, .2), 0 1px 1px rgba(0, 0, 0, .35);
		color: $white;
		display: block;
		font-size: 1.25em;
		height: 40px;
		line-height: 42px;
		text-align: center;
		width: 40px;

		@include media($tablet) {
			font-size: 1.5em;
			height: 48px;
			line-height: 50px;
			width: 48px;
		}

		&.nav-social-facebook:hover { background-color: #3B5998; }
		&.nav-social-twitter:hover { background-color: #55ACEE; }
		&.nav-social-pinterest:hover { background-color: #CB2027; }
		&.nav-social-instagram:hover { background-color: #125688; }
		&.nav-social-youtube:hover { background-color: #B00; }
		&.nav-social-google-plus:hover { background-color: #DD4B39; }

		span {
			display: none;
		}

		&:hover {
			color: $white;
			background-color: $orange;
		}
	}
} // .nav-social

.nav-footer {
	li {
		display: block;
		margin-bottom: 1em;
		margin-right: 2em;
		text-transform: uppercase;

		@include media($tablet) {
			display: inline;
			margin-bottom: 0;
		}

		&:last-child {
			margin-right: 0;
		}

		&.nav-active > a,
		&.current-menu-item > a {
			color: $white;
		}
	}

	a {
		color: $grey-1;

		&:hover {
			color: $white;
		}
	}
} // .nav-footer

.pagination {
	@extend .clearfix;
	clear: both;
	padding: 2em 0 0;
	text-align: center;

	a, span, &-space {
		@extend .small;
		@include size(30px);
		display: inline-block;
		line-height: 30px;
		font-size: 1.1rem;
		text-decoration: none !important;
		margin: 0 5px;
		cursor: pointer;
		color: $orange;

		&:hover {
			color: $teal;
		}

		&.current {
			color: $text;
			background-color: $grey;
			border-radius: 3px;
			cursor: default;
		}

		.oamm-videos & {
			color: #FFF;

			&:hover {
				color: $teal;
			}

			&.current {
				color: #2F2F2F;
			}
		}
	}

	&-space {
		cursor: default !important;
		color: $text !important;
	}
} // .pagination
