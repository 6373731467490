$videos-background: #2F2F2F;

.oamm-videos {
	color: #FFF;
	background-color: $videos-background;
	padding: 0 0 2rem;

	.container {
		max-width: 900px;

		&__full {
			width: 100%;
			max-width: 100%;
			padding: 0 1rem;

			@include media($desktop) {
				padding: 0 2rem;
			}
		}
	}

	&-header {
		@extend .clearfix;
		padding: 1rem 0;

		$videos-header-height: $default-font-size * 2.4;

		&-logo {
			float: left;
			color: #FFF !important;
			font-size: $videos-header-height - 9;
			line-height: $videos-header-height;
			font-weight: 600;

			.fonts-loaded & {
				font-weight: 400;
				font-family: $proxima-600;
			}

			i.fa {
				margin-right: 8px;
			}
		}

		&-search {
			float: right;
			border: 1px solid #FFF;
			border-radius: 5px;
			height: $videos-header-height;

			&-input {
				@include placeholder(#FFF);
				background: none;
				border: 0 !important;
				box-shadow: none;
				color: #FFF !important;
				height: $videos-header-height - 2;
				line-height: $videos-header-height - 2;
				padding: 0 10px;
				width: 130px;

				@include media($tablet) {
					width: 170px;
				}
			}

			&-button {
				appearance: none;
				background: none;
				border: 0;
				color: #FFF;
				height: $videos-header-height - 2;
				line-height: $videos-header-height - 2;
				padding: 0 10px;
				margin-top: -2px;
			}
		}

		.post-edit-link {
			display: inline-block;
			line-height: $videos-header-height - 14px;
			background-color: $orange;
			color: #FFF;
			border-radius: 3px;
			padding: 0 10px;
			margin: 7px 0 7px 10px;

			&:hover {
				color: #FFF;
				background-color: $teal;
			}
		}
	} // .oamm-videos-header

	&-video {
		text-align: center;
		background-color: #000;
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;

		&--1 {
			padding-bottom: 100%;

			@include media($desktop) {
				padding-bottom: 500px;

				iframe {
					width: 500px !important;
					height: 500px !important;
					left: 50% !important;
					margin-left: -250px;
				}
			}
		}

		&--3_5 {
			padding-bottom: 100%;

			@include media($desktop) {
				padding-bottom: 500px;

				iframe {
					width: 282px !important;
					height: 500px !important;
					left: 50% !important;
					margin-left: -141px;
				}
			}
		}

		&--4_3 {
			padding-bottom: 75%;
		}

		&--member-only {
			height: auto;
			padding: 0;
		}

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		.oamm-videos-member-only {
			top: 1rem;
			right: 1rem;
		}

		.oamm-videos-play-button {
			position: absolute;
			top: 50%;
			font-size: 4rem;
			margin-top: -2rem;
			width: 4rem;
			left: 50%;
			margin-left: -2rem;
			text-align: center;
		}
	} // .oamm-videos-video

	&-category {
		margin: 1rem 0;
		text-transform: uppercase;
		font-weight: 600;

		.fonts-loaded & {
			font-weight: 400;
			font-family: $proxima-600;
		}

		a {
			color: $teal-0;

			&:hover {
				color: $teal-0;
				text-decoration: underline;
			}
		}
	}

	&-title {
		margin: 1rem 0;

		a {
			color: #FFF !important;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	&-description {
		@include media($desktop) {
			float: left;
			width: 66.6%;
			margin-bottom: 2rem;
		}

		&--full-width {
			float: none;
			width: 100%;
		}

		p {
			font-size: 1.2rem;
			margin: 1.5rem 0;
			line-height: 1.45;
		}
	}

	&-sidebar {
		@include media($desktop) {
			float: right;
			width: 30%;
		}

		&-feature {
			@extend .clearfix;
			margin-bottom: 1rem;
		}

		&-label {
			text-transform: uppercase;
		}

		.card {
			width: 100% !important;
		}

		&-blog-post {
			margin-bottom: 2rem;

			h5 {
				font-size: 1.2rem;
				margin: 10px 0;
			}

			a {
				color: $teal-0;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	} // .oamm-videos-sidebar

	&-carousel {
		margin: 0 -1rem 2rem;

		@include media($desktop) {
			margin: 0 -2rem 2rem;
		}

		&-video {
			height: 150px;
			background-color: #555;
			background-position: 50% 50%;
			background-repeat: no-repeat;
			background-size: 100% auto;
			padding: 2rem;
			display: flex !important;
			flex-direction: column;
			justify-content: flex-end;
			position: relative;

			@include media($mobile-landscape) {
				height: 240px;
			}

			@include media($tablet) {
				height: 300px;
			}

			@include media($tablet-wide) {
				height: 400px;
			}

			@include media($desktop) {
				height: 500px;
			}

			&:before {
				@extend .cover;
				content: '';
				z-index: 1;
				background: linear-gradient(to bottom, rgba(#000, .5) 0%, rgba(#000, .9) 85%);
			}

			&.slick-current:before {
				background: linear-gradient(to bottom, rgba(#000, 0) 35%, rgba(#000, .65) 100%);
			}

			.oamm-videos-title, .oamm-videos-category {
				position: relative;
				z-index: 2;
			}

			.oamm-videos-category {
				display: none;

				@include media($tablet) {
					display: block;
				}
			}

			.oamm-videos-title {
				margin: 0;
				font-size: 1rem;

				@include media($mobile-landscape) {
					font-size: 1.2rem;
				}

				@include media($tablet) {
					font-size: 1.5rem;
				}

				@include media($desktop) {
					font-size: 1.8rem;
				}
			}

			.oamm-videos-play-button {
				position: absolute;
				z-index: 2;
				top: 50%;
				font-size: 4rem;
				margin-top: -2rem;
				width: 4rem;
				left: 50%;
				margin-left: -2rem;
				text-align: center;
			}

			.oamm-videos-member-only {
				top: 1rem;
				right: 1rem;
			}
		} // .oamm-videos-carousel-video

		.slick-arrow {
			color: #FFF;
			font-size: 4rem;
			margin-top: -2rem;
			z-index: 1;

			&:hover {
				color: $teal-0;
			}
		}

		.slick-prev {
			left: 1rem;
		}

		.slick-next {
			right: 1rem;
		}
	} // .oamm-videos-carousel

	&-row-carousel {
		position: relative;
		margin-bottom: 3rem;

		&-video {
			position: relative;
			margin-right: 1rem;

			.oamm-videos-title {
				color: #FFF;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				line-height: 1.3rem;
				max-height: 2.6rem;
				margin: 10px 0 0;
				font-weight: 600;

				.fonts-loaded & {
					font-weight: 400;
					font-family: $proxima-600;
				}

				&:hover {
					color: #FFF;
					text-decoration: underline;
				}
			}
		}

		.slick-arrow {
			color: #FFF;
			z-index: 1;
			margin-top: -4rem;

			@include media($mobile-landscape) {
				margin-top: -3rem;
			}

			&:hover {
				color: $teal-0;
			}
		}

		.slick-prev {
			display: none;
		}

		.slick-next {
			right: -8px;
		}
	} // .oamm-videos-row-carousel

	&-play-button {
		color: #FFF !important;
		font-size: 2rem;

		&:hover {
			color: $teal !important;
		}
	}

	&-archive {
		@extend .clearfix;
		border-top: 1px solid darken($grey-2, 30%);
		border-bottom: 1px solid darken($grey-2, 30%);
		padding: 2rem 0;
		margin-bottom: -1px;

		&-thumbnail {
			display: block;
			position: relative;

			@include media($tablet) {
				float: left;
				width: 20%;
			}
		}

		&-title, &-exceprt {
			@include media($tablet) {
				float: right;
				width: 76.666%;
			}
		}

		&-title {
			color: #FFF !important;
			font-weight: 600;
			font-size: 1.2rem;

			&:hover {
				text-decoration: underline;
			}

			.fonts-loaded & {
				font-weight: 400;
				font-family: $proxima-600;
			}
		}

		&-exceprt p {
			margin-bottom: 0;
		}
	} // .oamm-videos-archive

	&-member-only {
		position: absolute;
		top: 5px;
		right: 5px;
		color: #FFF !important;
		background-color: $orange;
		display: inline-block;
		padding: 3px 8px;
		font-size: 12px;
		font-weight: 700;
		line-height: 1;
		text-transform: uppercase;
		letter-spacing: 1px;
		border-radius: 10px;

		.fonts-loaded & {
			font-weight: 400;
			font-family: $proxima-700;
		}
	}
} // .oamm-videos
