.mb {
	background-color: #FFF;

	@include media($tablet) {
		background-color: $grey-0;
	}

	&-search > form {
		@include media($tablet) {
			display: flex;
		}
	}

	&-content {
		max-width: 900px;
		padding: 0 1rem;

		@include media(new-breakpoint(min-width 900px 8)) {
			margin: 0 auto;
			padding: 20px 0;
		}

		.mb-search & {
			padding: 1rem;

			@include media($tablet) {
				max-width: none;
				width: 75%;
				flex: 4 0 0;
				margin: 0;
				padding: 2rem;
				border-left: 1px solid #FFF;
			}
		}

		> .warning {
			margin-top: 2rem;
			box-shadow: 0 1px 3px 0 rgba(#000, .05), 0 3px 1px -2px rgba(#000, .05), 0 1px 4px 0 rgba(#000, .05);
		}

		a {
			text-decoration: underline;
		}
	} // .mb-content

	&-description {
		background: url('../images/separator.png') no-repeat 50% 0;
		background-size: 150% auto;
		padding-top: 1rem;
		margin-top: 10px;

		@include media($tablet) {
			margin-top: -10px;
		}

		&__edit {
			background: none;
			padding-top: 0;

			.sub-title-text {
				display: inline-block;
			}
		}
	} // .mb-description

	&-banner {
		margin: 2rem 0 1rem;

		img {
			border-radius: 5px;
		}
	}

	&-serving-day {
		display: block;
		margin: 1.2rem 0 8px;
	}

	&-numbers {
		@extend .clearfix;
		width: 90%;
		max-width: 450px;
		margin: 2rem auto;
		text-align: center;

		@include media($tablet) {
			display: none;
		}

		$numbers-width: 320px;

		.mb-stats-bar & {
			display: none;
			position: absolute;
			left: 50%;
			margin: 0;
			margin-left: -($numbers-width / 2);
			top: 15px;
			width: $numbers-width;
			height: 50px;

			@include media($tablet) {
				display: block;
			}

			&__left {
				float: left;
				position: relative;
				left: 0;
				top: 0;
				text-align: left;
				margin-left: 0;
				width: auto;
				max-width: none;
				height: 40px;
				margin: 20px 0 0;
			}
		}

		&-item {
			display: inline-block;
			width: 31%;
			text-align: center;

			@include media($mobile-landscape) {
				width: 19%;
			}

			@include media($tablet) {
				width: 31%;
			}

			&:nth-child(n+4) {
				display: none;

				@include media($mobile-landscape) {
					display: inline-block;
				}
			}

			.mb-numbers__left & {
				width: auto;
				margin-right: 1rem;
			}

			&-number {
				display: block;
				font-weight: 900;
				font-size: 2.4rem;

				.fonts-loaded & {
					font-family: $proxima-900;
					font-weight: 400;
				}

				.mb-numbers__left & {
					float: left;
					line-height: 40px;
					margin-right: 5px;
				}
			}

			&-label {
				@extend .small;
				display: block;
				color: $black-0;

				.mb-numbers__left & {
					float: left;
					line-height: 40px;
				}
			}
		}
	} // .mb-numbers

	&-media {
		margin-bottom: 2rem;

		@include media($tablet) {
			float: right;
		}

		@include media($desktop) {
			margin-right: -140px;
		}

		&-slot {
			width: 300px;
			margin: 0 auto 2rem;
		}
	}

	&-print {
		border-radius: 100px !important;
		border: 0 !important;
		text-shadow: none !important;
		box-shadow: none !important;
		margin-top: -4rem;

		@include media($tablet) {
			margin-top: -4.7rem;
		}
	}

	&-custom-menu-title {
		margin: 0;
		padding: 1rem 0 0;

		@include media(new-breakpoint(min-width 900px 8)) {
			padding: 0;
		}
	}

	&-resource-title {
		margin-top: 2rem;
		width: 75%;

		@include media($tablet) {
			width: 100%;
		}

		&__small {
			margin-bottom: 0;
		}
	}
} // .mb

.callout {
	border-radius: 3px;
	background-color: $teal;
	color: #FFF;
	padding: 1rem .5rem;
	text-align: center;
	box-shadow: 0 1px 3px 0 rgba(#000, .075), 0 3px 1px -2px rgba(#000, .075), 0 1px 4px 0 rgba(#000, .075);

	@include media($tablet) {
		padding: 1rem;
		text-align: left;
	}

	@include media($desktop) {
		color: $text;
		text-shadow: 0 0 4px #FFF;
		background: #FFF url('../images/bg-freezer-bags.jpg') no-repeat 103% 50%;
		background-size: 80% auto;
		position: relative;

		&:before {
			@include cover;
			content: '';
			background-color: rgba(255, 255, 255, .4);
			background: linear-gradient(90deg, rgba(#FFF, 1) 0%, rgba(#FFF, 0) 85%);
		}
	}

	* {
		position: relative;
	}

	&-close {
		float: right;
		color: $white !important;
		z-index: 1;
		margin-right: .5rem;

		@include media($tablet) {
			margin-right: 0;
		}

		@include media($desktop) {
			color: $text !important;
		}

		&:hover {
			color: $orange;
		}
	}

	&-title {
		margin: 0;
		font-size: 1.4rem;
	}

	&-text {
		margin: .5rem 0 1rem;
		line-height: 1.4;

		@include media($desktop) {
			padding-right: 40%;

			.mb-search & {
				padding-right: 50%;
			}
		}
	}

	a.callout-cta {
		text-shadow: none;

		@include media($desktop) {
			background-color: $teal;
		}

		&:hover {
			background-color: $text;
		}
	}
} // .callout

.oamm-lightbox {
	display: none;
	position: fixed;
	z-index: 999999999;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(#000, .78);

	&-close {
		color: #FFF;
		font-size: 1.4rem;
		position: absolute;
		top: 2rem;
		right: 2rem;
	}

	&-content {
		display: none;
		position: absolute;
		top: 40%;
		left: 50%;
		width: 300px;
		margin-left: -150px;
		padding: 2rem;
		border-radius: 3px;
		background-color: #FFF;

		@include media($tablet) {
			top: 35%;
			width: 445px;
			margin-left: -(445px / 2);
		}

		> p {
			margin-top: 0;
		}
	}
} // .oamm-lightbox

.oamm-locked {
	background: $grey-0;
	border-radius: 3px;
	border: 1px dashed $teal;
	margin: 1rem 0;
	padding: 2rem;
	text-align: center;
	font-size: 1.4rem;

	@include media($tablet) {
		background: #FFF;
		padding: 4rem 2rem;
	}

	&-icon {
		font-size: 4rem;
	}

	&-title {
		color: $teal;
		font-size: 1.8rem;
	}
} // .oamm-locked
