.block-newsletter {
	//background: url('../images/newsletter-menus.png') no-repeat 50% 110%;
	//padding-bottom: 16rem !important;

	@include media($tablet) {
		//padding-bottom: 21rem !important;
	}

	footer.main & {
		margin-top: -80px;
		margin-bottom: 80px;
	}

	form {
		@extend .clearfix;
		margin-bottom: 1rem;

		.input-row-col {
			width: 47.5%;
			margin-right: 5%;

			&:last-of-type {
				margin-right: 0;
			}
		}

		select {
			margin-bottom: 1rem;
		}

		.btn {
			margin-top: 1rem;
		}

		.newsletter-fine-print {
			@extend .small;
			float: left;
			margin-top: 1rem;
			padding: 9px 0;
			line-height: 1rem;
		}
	}
} // .block-newsletter
