$selectize-font-family: inherit;
$selectize-font-smoothing: inherit;
$selectize-font-size: 13px;
$selectize-line-height: 18px;

$selectize-color-text: #303030;
$selectize-color-border: #D0D0D0;
$selectize-color-highlight: $teal-0;
$selectize-color-input: #FFF;
$selectize-color-input-full: $selectize-color-input;
$selectize-color-disabled: #FAFAFA;
$selectize-color-item: #F2F2F2;
$selectize-color-item-text: $selectize-color-text;
$selectize-color-item-border: #D0D0D0;
$selectize-color-item-active: #E8E8E8;
$selectize-color-item-active-text: $selectize-color-text;
$selectize-color-item-active-border: #CACACA;
$selectize-color-dropdown: #FFF;
$selectize-color-dropdown-border: $selectize-color-border;
$selectize-color-dropdown-border-top: #F0F0F0;
$selectize-color-dropdown-item-active: #F5FAFD;
$selectize-color-dropdown-item-active-text: #495C68;
$selectize-color-dropdown-item-create-text: rgba(red($selectize-color-text), green($selectize-color-text), blue($selectize-color-text), .5);
$selectize-color-dropdown-item-create-active-text: $selectize-color-dropdown-item-active-text;
$selectize-color-optgroup: $selectize-color-dropdown;
$selectize-color-optgroup-text: $selectize-color-text;
$selectize-lighten-disabled-item: 30%;
$selectize-lighten-disabled-item-text: 30%;
$selectize-lighten-disabled-item-border: 30%;
$selectize-opacity-disabled: .5;

$selectize-shadow-input: inset 0 1px 1px rgba(0, 0, 0, .1);
$selectize-shadow-input-focus: inset 0 1px 2px rgba(0, 0, 0, .15);
$selectize-border: 1px solid $selectize-color-border;
$selectize-dropdown-border: 1px solid $selectize-color-dropdown-border;
$selectize-border-radius: 3px;

$selectize-width-item-border: 0;
$selectize-max-height-dropdown: 240px;

$selectize-padding-x: 8px;
$selectize-padding-y: 8px;
$selectize-padding-item-x: 6px;
$selectize-padding-item-y: 2px;
$selectize-padding-dropdown-item-x: $selectize-padding-x;
$selectize-padding-dropdown-item-y: 5px;
$selectize-margin-item-x: 3px;
$selectize-margin-item-y: 3px;

$selectize-arrow-size: 5px;
$selectize-arrow-color: #808080;
$selectize-arrow-offset: 15px;

$selectize-caret-margin: 0 2px 0 0;
$selectize-caret-margin-rtl: 0 4px 0 -2px;

@mixin selectize-border-radius ($radii) {
	border-radius: $radii;
}

@mixin selectize-unselectable () {
	user-select: none;
}

@mixin selectize-box-shadow ($shadow) {
	box-shadow: $shadow;
}

@mixin selectize-box-sizing ($type: border-box) {
	box-sizing: $type;
}

@mixin selectize-vertical-gradient ($color-top, $color-bottom) {
	background-color: mix($color-top, $color-bottom, 60%);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from($color-top), to($color-bottom)); // Safari 4+, Chrome 2+
	background-image: linear-gradient(to bottom, $color-top, $color-bottom); // Standard, IE10
	background-repeat: repeat-x;
}

.selectize-dropdown, .selectize-input, .selectize-input input {
	color: $text;
	line-height: 1.2rem;
}

.selectize-input, .selectize-control.single .selectize-input.input-active {
	background: $selectize-color-input;
	cursor: text;
	display: inline-block;
}

.selectize-input {
	padding: 10px 1rem;
	display: inline-block;
	width: 100%;
	overflow: hidden;
	position: relative;
	z-index: 1;
	border: 1px solid $grey-1;
	box-shadow: 0 1px 2px rgba(0, 0, 0, .15);
	font-size: 1rem;

	&::after {
		content: ' ';
		display: block;
		clear: left;
	}

	.selectize-control.multi &.has-items {
		$padding-x: $selectize-padding-x;
		$padding-top: $selectize-padding-y - $selectize-padding-item-y - $selectize-width-item-border;
		$padding-bottom: $selectize-padding-y - $selectize-padding-item-y - $selectize-margin-item-y - $selectize-width-item-border;
		padding: $padding-top $padding-x $padding-bottom;
	}

	&.full {
		background-color: $selectize-color-input-full;
	}

	&.disabled, &.disabled * {
		cursor: default !important;
	}

	&.focus {
		@include selectize-box-shadow($selectize-shadow-input-focus);
	}

	&.dropdown-active {
		&::before {
			content: ' ';
			display: block;
			position: absolute;
			background: $selectize-color-dropdown-border-top;
			height: 1px;
			bottom: 0;
			left: 0;
			right: 0;
		}
	}

	> * {
		vertical-align: baseline;
		display: -moz-inline-stack;
		display: inline-block;
		zoom: 1;
		*display: inline;
	}

	.selectize-control.multi & > div {
		cursor: pointer;
		margin: 0 $selectize-margin-item-x $selectize-margin-item-y 0;
		padding: $selectize-padding-item-y $selectize-padding-item-x;
		background: $selectize-color-item;
		color: $selectize-color-item-text;
		border: $selectize-width-item-border solid $selectize-color-item-border;

		&.active {
			background: $selectize-color-item-active;
			color: $selectize-color-item-active-text;
			border: $selectize-width-item-border solid $selectize-color-item-active-border;
		}
	}

	.selectize-control.multi &.disabled > div {
		&, &.active {
			color: lighten(desaturate($selectize-color-item-text, 100%), $selectize-lighten-disabled-item-text);
			background: lighten(desaturate($selectize-color-item, 100%), $selectize-lighten-disabled-item);
			border: $selectize-width-item-border solid lighten(desaturate($selectize-color-item-border, 100%), $selectize-lighten-disabled-item-border);
		}
	}

	> input {
		@include selectize-box-shadow(none);
		display: inline-block !important;
		padding: 0 !important;
		min-height: 0 !important;
		max-height: none !important;
		max-width: 100% !important;
		margin: $selectize-caret-margin !important;
		text-indent: 0 !important;
		border: 0 none !important;
		background: none !important;
		line-height: inherit !important;

		&::-ms-clear {
			display: none;
		}

		&:focus {
			outline: none !important;
		}
	}
}

.selectize-dropdown {
	position: absolute;
	z-index: 10;
	background: $selectize-color-dropdown;
	margin: -1px 0 0;
	border-top: 0;
	border-radius: 0 0 $selectize-border-radius $selectize-border-radius;

	[data-selectable] {
		cursor: pointer;
		overflow: hidden;

		.highlight {
			background: $selectize-color-highlight;
			border-radius: 1px;
		}
	}

	[data-selectable], .optgroup-header {
		padding: 5px 1rem;
	}

	.optgroup:first-child .optgroup-header {
		border-top: 0;
	}

	.optgroup-header {
		background: $selectize-color-optgroup;
		cursor: default;
	}

	.active {
		background-color: $teal-0;
	}
}

.selectize-dropdown-content {
	overflow-y: auto;
	overflow-x: hidden;
	max-height: $selectize-max-height-dropdown;
	-webkit-overflow-scrolling: touch;
	border: 1px solid $grey-1;
}

.selectize-control {
	position: relative;

	&.single .selectize-input {
		&, input {
			cursor: pointer;
		}

		&.input-active, &.input-active input {
			cursor: text;
		}

		&:after {
			content: ' ';
			display: block;
			position: absolute;
			top: 50%;
			right: $selectize-arrow-offset;
			margin-top: round((-1 * $selectize-arrow-size / 2));
			width: 0;
			height: 0;
			border-style: solid;
			border-width: $selectize-arrow-size $selectize-arrow-size 0;
			border-color: $selectize-arrow-color transparent transparent;
		}

		&.dropdown-active:after {
			margin-top: $selectize-arrow-size * -.8;
			border-width: 0 $selectize-arrow-size $selectize-arrow-size;
			border-color: transparent transparent $selectize-arrow-color;
		}
	}

	.rtl {
		&.single .selectize-input:after {
			left: $selectize-arrow-offset;
			right: auto;
		}

		.selectize-input > input {
			margin: $selectize-caret-margin-rtl !important;
		}
	}

	.selectize-input.disabled {
		opacity: $selectize-opacity-disabled;
		background-color: $selectize-color-disabled;
	}
} // .selectize-control
