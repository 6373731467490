html, body, #mobile-perspective {
	width: 100%;
	height: 100%;
}


.mobile-container {
	background: $white;
	min-height: 100%;
	outline: 1px solid rgba(0, 0, 0, 0);
	position: relative;

	.menu-single &, .recipe-single & {
		background-color: $grey-0;
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 0;
		opacity: 0;
		background: rgba(0, 0, 0, .2);
		// the transition delay of the height needs to be synced with the container transition time
		transition: opacity .4s, height 0s .4s;
	}

	.animate & {
		box-shadow: -1px 0 20px rgba(0, 0, 0, .25);
	}

	.modalview & {
		position: absolute;
		overflow: hidden;
		width: 100%;
		height: 100%;
		cursor: pointer;
		backface-visibility: hidden;
	}
} // .mobile-container

.mobile-wrapper {
	position: relative;
}

// Modal view
.modalview .mobile-wrapper {
	transform: translateZ(-1px); // solves a rendering bug in Chrome on Windows
}

.animate .mobile-container:after {
	opacity: 1;
	height: 101%;
	transition: opacity .3s;
}

// NAV - MOBILE
.nav-mobile {
	position: absolute;
	z-index: -1;
	height: auto;
	font-size: 16px;
	top: 52.5%;
	left: 25px;
	transform: translateY(-50%);
	transform-style: preserve-3d;

	> ul > li {
		display: block !important;
		float: none !important;
	}

	$nav-spacing: 1.2rem;

	.nav-divider {
		height: 1px;
		border-top: 1px solid #E8EDF0;
		font-size: 0;
		margin: 0 0 $nav-spacing;
	}

	a {
		display: block;
		white-space: nowrap;
		margin: 0 0 $nav-spacing;
		color: $orange;
		transition: color .3s;
		transform-style: preserve-3d;
		text-decoration: none;
		text-transform: uppercase;

		&:hover {
			color: $teal;
		}

		&.logo {
			margin-top: 1em;

			@media screen and (min-height: 650px) {
				margin-top: 4em;
			}
		}
	}
} // .nav-mobile

// Effect Move Left
#mobile-perspective {
	position: relative;
	background: $grey-0;

	.mobile-container {
		transition: transform .4s;
		transform-origin: 50% 50%;

		&:after {
			background: rgba(255, 255, 255, .6);
		}
	}

	&.modalview {
		position: fixed;
		perspective: 1500px;

		.mobile-container {
			z-index: 10;
			transform: translateZ(0) translateX(0) rotateY(0deg);
		}
	}

	&.animate .mobile-container {
		transform: translateX(50%) rotateY(-35deg) translateZ(-15px);
	}

	&.animate .nav-mobile {
		a {
			opacity: 1;
			transform: translateX(0) translateZ(0);
		}

		li:nth-of-type(2) a {
			transition-delay: .02s;
		}

		li:nth-of-type(3) a {
			transition-delay: .04s;
		}

		li:nth-of-type(4) a {
			transition-delay: .06s;
		}

		li:nth-of-type(5) a {
			transition-delay: .08s;
		}

		li:nth-of-type(6) a {
			transition-delay: .10s;
		}

		li:nth-of-type(7) a {
			transition-delay: .12s;
		}

		li:nth-of-type(8) a {
			transition-delay: .14s;
		}

		li:nth-of-type(9) a {
			transition-delay: .16s;
		}

		li:nth-of-type(10) a {
			transition-delay: .18s;
		}

		li:nth-of-type(11) a {
			transition-delay: .20s;
		}

		li:nth-of-type(12) a {
			transition-delay: .22s;
		}

		li:nth-of-type(13) a {
			transition-delay: .24s;
		}

		li:nth-of-type(14) a {
			transition-delay: .26s;
		}

		li:nth-of-type(15) a {
			transition-delay: .28s;
		}

		.logo {
			transition-delay: .30s;
		}
	}

	.nav-mobile a {
		opacity: 0;
		transform: translateX(100px) translateZ(-1000px);
		transition: transform .4s, opacity .4s;
	}
}

.no-csstransforms3d #mobile-perspective.animate .mobile-container {
	left: -75%;
}


@media screen and (max-width: 31em), screen and (max-height: 36.2em) {
	.nav-mobile {
		width: 7.5em;
	}
}

@media screen and (max-height: 31.6em) {
	.nav-mobile a {
		margin-bottom: 1em;
	}
}
