.strength {
	background-color: $grey;
	border-radius: 12px;

	span {
		@extend .small;
		display: none;
		margin-top: 5px;
		padding: .5em 1em;
		border-radius: 12px;
		transition: all .3s ease;
	}

	&.is-weak {
		span.weak {
			display: block;
			color: #FFF;
			background-color: $error;
			width: 40%;
		}
	}

	&.is-medium {
		span.medium {
			display: block;
			background-color: #FFF59D;
			width: 66%;
		}
	}

	&.is-strong {
		span.strong {
			display: block;
			color: #FFF;
			background-color: $success;
		}
	}
} // .strength

.coupon-code {
	text-align: center;

	input {
		width: auto !important;
		text-align: center;
	}

	.btn {
		padding-top: 11px;
		padding-bottom: 11px;
	}
} // .coupon-code

#cancel_note {
	&_other {
		width: 100%;
		margin-top: 10px;

		@include media($mobile-landscape) {
			position: absolute;
			margin-left: 1rem;
			width: 300px;
			max-width: 100%;
			margin-top: -.75rem;
		}
	}
} // #cancel_note
