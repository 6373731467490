/***
 * Buttons:
 * Create buttons with the `.btn` class on a `<a>`, `<button>`, or `<input type="submit">` tag.
 */

/**
 * Default button:
 *
 *     @example
 *     a.btn Link
 *     input.btn(type='submit' value='Submit')
 *     button.btn Button
 */

.btn, a.btn, .button {
	background-color: $orange;
	border-radius: 100px;
	border: 0;
	color: $white;
	display: inline-block;
	font-family: $proxima-700;
	line-height: 1;
	padding: 8px .85em;
	text-align: center;
	text-decoration: none !important;
	text-shadow: none;
	text-transform: uppercase;
	transition: all .2s ease;
	cursor: pointer;

	@include media($tablet-wide) {
		padding-left: 1.25em;
		padding-right: 1.25em;
	}

	/**
	 * Alternate button colors:
	 *
	 * There are 2 alternative button colors: grey and teal.
	 *
	 *     @example
	 *     a.btn.btn-grey Grey
	 *     a.btn.btn-teal Teal
	 */

	&.btn-grey, &.btn-gray, &__grey {
		background-color: $text;
	}

	&.btn-teal, &.btn__teal {
		background-color: $teal;

		&:hover {
			background-color: $text;
		}
	}

	/**
	 * Button alignment:
	 *
	 *     @example
	 *     a.btn.btn-full Full Width
	 *     a.btn.btn-right Right Aligned
	 */

	&.btn-full, &__full {
		width: 100%;
	}

	&.btn-right, &__right {
		float: right;
	}

	&.btn-center {
		margin-left: auto;
		margin-right: auto;
		display: block;
	}

	/**
	 * Button sizes:
	 *
	 *     @example
	 *     a.btn.btn-large Large
	 *     a.btn.btn-small Small
	 */

	&.btn-large, &.btn__large {
		font-size: 21px;
		font-family: $proxima-700;
		padding: 10px 2em;
	}

	&.btn-small {
		font-size: 12px;
		padding: 8px .65em;
	}

	/**
	 * Social media buttons:
	 *
	 *     @example
	 *     a.btn.btn-pin Pinterest
	 *     a.btn.btn-facebook Facebook
	 *     a.btn.btn-twitter Twitter
	 */

	&.btn-pin, &.btn-facebook, &.btn-twitter, &.btn-google-plus {
		box-shadow: none;
	}

	&.btn-pin {
		background-color: #BD081C;
		border-color: #BD081C;

		&:hover {
			background-color: #AA0719;
			text-shadow: none;
			border-color: #AA0719;
		}
	}

	&.btn-facebook {
		background-color: #4267B2;
		border-color: #4267B2;

		&:hover {
			background-color: #365899;
			text-shadow: none;
			border-color: #365899;
		}
	}

	&.btn-google-plus {
		background-color: #DF4B37;
		border-color: #DF4B37;

		&:hover {
			background-color: #C44133;
			text-shadow: none;
			border-color: #C44133;
		}
	}

	&.btn-twitter {
		background-color: #1B95E0;
		border-color: #1B95E0;

		&:hover {
			background-color: #0C7ABF;
			text-shadow: none;
			border-color: #0C7ABF;
		}
	}

	&__shopping {
		i {
			float: left;
			margin-top: -1px;
			margin-right: 5px;
		}
	}

	&:hover {
		color: $white;
		background-color: $teal;
	}

	.block-teal &:hover {
		background-color: $black;
	}

	&:active {
		//box-shadow: 0 1px 2px rgba(0, 0, 0, .25), inset 0 1px 1px rgba(255, 255, 255, .35), inset 0 -1px 1px rgba(0, 0, 0, .1), inset 0 2px 4px rgba(0, 0, 0, .5);
	}

	&:disabled {
		background-color: $grey-1;
		//border-color: $grey-2;
		cursor: not-allowed;
	}

	&.disabled {
		pointer-events: none;
		cursor: not-allowed;
	}

	&__disabled {
		color: $grey-2;
		background-color: $grey-1;
		pointer-events: none;
		cursor: not-allowed;
	}

	&.btn-favorites {
		width: 100%;
		text-align: center;

		@include media($tablet-wide) {
			float: right;
			width: auto;
			margin-bottom: -1rem;
		}
	}

	a {
		color: inherit;
	}
} // .btn

.btn-new-menu {
	float: right;
	margin-top: 1rem;

	@include media($tablet-wide) {
		margin-top: 0;
	}

	&.disabled {
		background: $grey-2;
	}

	+ h2 {
		margin-top: 0;
		padding-top: 1rem;
		line-height: 32px;

		@include media($tablet-wide) {
			padding-top: 0;
		}
	}
} // .btn-new-menu
