.mb-recipe-instructions {
	@include media($tablet) {
		float: right;
		width: percentage(600 / 900);
		margin-top: 1rem;
	}

	.info {
		margin: 1rem 0;
	}

	&-section {
		.mb-recipe-instructions__media & {
			@include media($tablet) {
				float: left;
				clear: left;
				width: 100%;
				margin-right: -320px;
				padding-right: 320px;
			}

			@include media($desktop) {
				width: 420px;
				margin-right: 0;
				padding-right: 0;
			}
		}

		time {
			float: right;
			font-family: $serif;
			font-size: .8rem;
			font-style: italic;
			line-height: 26px;

			@include media($tablet) {
				line-height: 31px;
			}

			i.fa {
				display: none;

				@include media($tablet) {
					display: inline-block;
				}
			}
		} // time

		&-title {
			margin-top: 0;
			margin-bottom: 5px;

			@include media($tablet) {
				margin-bottom: 7px;
			}

			.headline-label {
				margin-top: 4px;
			}
		}

		&-list {
			margin: 1rem 0;
			padding-left: 1.2rem;
			line-height: 1.3;

			li {
				margin-bottom: 8px;
			}
		}
	} // .mb-recipe-instructions-section

	&-cook-type {
		@extend .small;
		color: $black-0;
	}
} // .mb-recipe-instructions

.mb-tags {
	display: none;

	@include media($tablet) {
		float: right;
		display: block;
		width: 66.6667%;
		margin-bottom: 3rem;
	}

	@include media($desktop) {
		width: 600px;

		&__media {
			padding-right: 180px;
		}
	}

	&-title {
		margin-top: 0;
	}
} // .mb-tags

.js_addtomenu {
	font-size: 1rem;
} // .js_addtomenu
