.mb-sidebar {
	@extend .clearfix;
	background-color: $grey-1;
	padding: 1rem 1rem 2rem;

	@include media($tablet) {
		width: 25%; // For old browsers
		min-width: 300px + ($default-font-size * 2 * 2); // 1rem * 2 (padding) * 2 (sides)
		max-width: 400px;
		flex: 1 0 0;
		padding: 2rem;
		border-right: 1px solid darken($grey-1, 3%);
	}

	.recipe-single &, .menu-single & {
		min-height: auto;
	}

	.mb-media-slot {
		display: none;

		@include media($tablet) {
			display: block;
		}
	}

	&-nav {
		@extend .list-reset;
		@extend .clearfix;
		background-color: $text;
		position: relative;
		font-family: $proxima-600;

		@include media($tablet) {
			background-color: transparent;
			margin: -1rem -2rem 3rem;
		}

		> li {
			float: left;
			display: block;
			text-align: center;
			width: 25%;

			@include media($tablet) {
				float: none;
				width: auto;
				text-align: left;
			}

			> a {
				padding: .5rem .25rem;
				margin: .5rem 0;
				display: inline-block;
				color: #FFF;
				text-decoration: underline;
				border-radius: 3px;

				@include media($mobile-wide) {
					padding: .5rem;
				}

				@include media($tablet) {
					padding: 1rem ($default-font-size * 2 - 4);
					margin: 0;
					display: block;
					color: $text;
					text-decoration: none;
					font-size: 1.2rem;
					line-height: 1.2rem;
					border-radius: 0;
					border-left: 4px solid $text;
				}

				&:hover {
					color: #FFF;
					background-color: $teal;
				}

				&.current {
					background-color: $orange;
					color: #FFF;
					text-decoration: none;

					@include media($tablet) {
						color: $text;
						border-color: $orange;
						background-color: #FFF;
						border-bottom: 1px solid darken($grey-1, 3%);
					}
				}

				.hide-mobile {
					@include media($mobile-landscape) {
						display: inline;
					}
				}

				i {
					float: left;
					font-size: 1rem;
					line-height: 1.2rem;
					width: 24px;
					text-align: center;
					margin-right: 6px;
					display: none;

					@include media($tablet) {
						display: inline-block;
					}

					&[class^='icon-'] {
						font-size: 1.2rem;
						margin-top: 3px;
					}
				}
			}
		}

		li.mb-sidebar-nav-last {
			width: auto;
			line-height: 1.2rem;
			float: right;
			display: inline;

			@include media($tablet) {
				float: none;
				display: block;
				line-height: 1rem;
			}

			> a {
				margin: -1rem -1rem 0 0;
				padding: 1rem;
				color: $orange;
				text-decoration: none;
				position: absolute;
				top: 100%;
				right: 0;
				text-transform: uppercase;

				@include media($tablet) {
					position: relative;
					font-size: 1rem;
					line-height: 1.4rem;
					padding: 1rem 1.5rem;
					margin: 1rem 2rem;
					border: 0;
					background-color: $orange;
					color: #FFF;
					border-radius: 100px;
					border: 2px solid $orange;
					text-align: center;

					&:hover {
						background-color: $teal;
						border-color: $teal;
					}
				}

				&:hover {
					background-color: transparent;
					color: $teal;

					@include media($tablet) {
						background-color: $teal;
						border-color: $teal;
						color: #FFF;
					}
				}

				i.fa, i.fas, i.fab {
					font-size: 14px;
				}
			}

			.submenu {
				top: 100%;
				right: 0;
				margin-top: 2rem;
				width: 290px;

				@include media($tablet) {
					right: 2rem;
					margin-top: 10px;
					width: 260px;
				}
			}
		}
	} // .mb-sidebar-nav

	&-filters {
		@include media($tablet) {
			margin: 0 0 2rem !important;
		}

		&.open {
			@extend .clearfix;
			position: relative;
			margin-top: 2rem;

			.filter, input[type="submit"], p {
				display: block;
			}
		}

		&-title {
			font-size: 1rem;
			line-height: 1.2rem;
			font-family: $proxima-600;
			text-transform: uppercase;
			display: inline-block;
			position: absolute;
			transition: none;

			@include media($tablet) {
				display: none;
			}

			&:hover, &:active {
				color: $text;
			}

			.open & {
				margin-top: -2rem;
			}
		}

		input[type="submit"], p {
			display: none;

			@include media($tablet) {
				display: block;
			}
		}
	} // .mb-sidebar-filters

	.filter {
		display: none;
		margin-bottom: 1rem;
		width: 100%;

		@include media($tablet) {
			display: block;
			float: none;
			margin-bottom: 1.5rem;
		}

		&.open {
			.filter-list {
				display: block;
			}
		}

		&-title {
			display: block;
			position: relative;
			margin-top: 0;
			margin-bottom: .5rem;
			color: $text;
			font-family: $proxima-600;
			text-transform: uppercase;

			a.tooltip {
				float: right;
				margin-left: 8px;
				color: #888;
				cursor: help;
				position: relative;

				&:before, &:after {
					visibility: hidden;
					opacity: 0;
					position: absolute;
					bottom: 125%;
					transition: all .2s ease;
				}

				&:before {
					z-index: 99;
					right: -1rem;
					margin-bottom: 6px;
					padding: 10px;
					width: 290px;
					border-radius: 5px;
					background: rgba(#000, .85);
					color: #FFF;
					content: attr(title);
					text-align: center;
					text-transform: none;
					line-height: 1.4;
					box-shadow: 0 1px 5px 0 rgba(#000, .5);
				}

				&:after {
					z-index: 100;
					right: 1px;
					width: 0;
					border-top: 7px solid rgba(#000, .85);
					border-right: 7px solid transparent;
					border-left: 7px solid transparent;
					content: ' ';
					font-size: 0;
					line-height: 0;
				}

				&:hover:before, &:hover:after {
					visibility: visible;
					opacity: 1;
				}
			}
		} // .filter-title

		&-search {
			position: relative;
			margin-bottom: .8rem;

			&.focus {
				box-shadow: 0 0 0 1px rgba($teal, .75), 0 2px 1px -2px rgba($teal, .5), 0 0 6px 0 rgba($teal, .75);
			}

			input[type="text"] {
				width: 100%;
				position: relative;
				padding-right: 2rem;
			}

			&-clear {
				position: absolute;
				right: 1rem;
				top: 12px;
				color: $grey-2;

				&:hover {
					color: $text;
				}
			}
		} // .filter-search

		&-clear {
			float: right;
			font-family: $proxima-400;
		} // .filter-clear

		&-list {
			@extend .list-reset;
			@include spacing(0 0 .4rem 1px, 0);
			max-height: 250px;

			&.open {
				overflow-y: scroll;
			}

			&__full {
				max-height: none;
				margin-top: 1rem;
			}

			&-item {
				margin-bottom: .4rem;
			}

			&-item-pin {
				float: right;
				color: $grey-2;

				&:hover {
					color: $text;
				}

				&.active {
					color: $teal;
				}
			} // .filter-list-item-pin
		} // .filter-list

		input[type="text"] {
			&::-webkit-input-placeholder { font-style: normal; color: $grey-2; }
			&:-ms-input-placeholder { font-style: normal; color: $grey-2; }
		}

		label {
			line-height: 1.1;

			&:hover {
				cursor: pointer;
			}
		}

		input[type="radio"], input[type="checkbox"] {
			float: left;
			position: relative;
			appearance: none;
			font-size: inherit;
			width: 1em;
			margin: 0 5px 0 0;
			color: inherit;
			outline: none;
			font-family: 'FontAwesome';
			font-weight: 400;
			transition: .5s ease-out;

			&::after {
				display: inline-block;
				text-align: center;
				width: 1em;
			}

			&:active {
				transform: scale(.6);
			}

			&:checked::after {
				font-weight: 900;
				color: $teal;
			}
		}

		input[type="radio"]::after {
			content: '\f111'; // circle
		}
		input[type="checkbox"]::after {
			content: '\f0c8'; // square
		}

		input[type="radio"]:checked::after {
			content: '\f192'; // dot-circle
		}
		input[type="checkbox"]:checked::after {
			content: '\f14a'; // check-square
		}
	} // .filter

	&-media-slot {
		display: none;

		@include media($tablet) {
			display: block;
			width: 300px;
			margin-top: 2rem;
		}
	} // .mb-sidebar-media-slot

	&-cards {
		display: none;

		@include media($tablet) {
			display: block;
		}

		.card {
			width: 100%;
			margin-right: 0;
		}
	} // .mb-sidebar-cards
} // .mb-sidebar
